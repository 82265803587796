import { Route, NavigationGuard, NavigationGuardNext } from 'vue-router'

import store from '@/store'
import UserEntity from '../entities/user.entity'
import RoleEnum from '../utils/roles'

/**
 * The auth navigation guard, which will be used to check whether
 * the used is already logged and its role. According to the result,
 * it will proceed to the path or will be redirected.
 *
 * @param {Route} to to where the application is navigating.
 * @param {Route} from from where the application request the redirection.
 * @param {NavigationGuardNext} next function responsible for redirecting the application.
 * @returns {NavigationGuard} a navigation guard to be used by the router.
 */
export default async function (to, from, next) {
  let authenticated = store.getters['auth/isAuthenticated']

  if (!authenticated) {
    await store.dispatch('auth/autoSignIn')
    authenticated = store.getters['auth/isAuthenticated']
  }

  const userRole = store.getters['auth/currentUserRole']

  if (authenticated && userRole === 6 && to.name === 'stats') {
    return next({ name: 'soon', params: { to: to.fullpath } })
  }

  // Check if user is on public route
  if (to.meta.isPublic) {
    return next()
  }

  // If unauthenticated but going to admin
  if (!authenticated && !to.meta.isPublic) {
    if (to.name !== 'login') {
      return next({ name: 'login', params: { to: to.fullPath } })
    }
  }

  // If authenticated and has access to route
  if (authenticated && to.meta.hasAccess.includes(userRole)) {
    if (noAccess(to)) {
      if (window.location.pathname === '/') {
        store.state.auth.noAccess = true
        return
      }

      return next({ name: 'Public Home', params: { reason: 'no-access' } })
    }

    return next()
  }

  if (to.name !== 'not-authorized') {
    return next({ name: 'not-authorized', params: { to: to.fullPath } })
  }

  next()
}

/**
 * Checks whether the user has no access to the admin panel.
 *
 * @param {Route} to to where the application is navigating.
 * @returns whether the user has no access to the route.
 */
function noAccess(to) {
  /**
   * @type {UserEntity}
   */
  const user = store.state.auth.user

  return (
    user &&
    user.role !== RoleEnum.SUPER_ADMIN &&
    !user.status &&
    !user.adminDocRef &&
    !user.sellerDocRef &&
    !user.sellerOfOpticDocRef &&
    to.fullPath.includes('/admin')
  )
}
