import AddressModel from '../models/address.model'
import Entity from './base.entity'
import { CreditEntity } from './credit.entity'
import MeasurementEntity from './measurement.entity'

/**
 * Class responsible for keeping the optic properties.
 */
export default class OpticEntity extends Entity {
  constructor({
    id,
    lastMeasurementId,
    nameRegistered,
    nameFantasy,
    active,
    logo,
    address,
    userEmail,
    createdAt,
    updatedAt,
    deletedAt,
    deletedBy,
    cnpj,
    adminId,
    sellers,
    qtyMeasurements,
    qtySellers,
    measurements,
    status,
    allowedMeasurements,
    availableMeasurements,
    activeSellers,
    creditHistory,
  } = {}) {
    super({ id, createdAt, updatedAt, deletedAt, deletedBy })
    /**
     * Property that defines the last measurement id created.
     *
     * @type {string}
     */
    this.lastMeasurementId = lastMeasurementId

    /**
     * Property that defines the optics registered name.
     *
     * @type {string}
     */
    this.nameRegistered = nameRegistered

    /**
     * Property that defines the optics fantasy name.
     *
     * @type {string}
     */
    this.nameFantasy = nameFantasy

    /**
     * Property that defines whether an optic is active.
     *
     * @type {boolean}
     */
    this.active = active ?? true

    /**
     * Property that defines the logo image URL.
     *
     * @type {string}
     */
    this.logo = logo ?? ''

    /**
     * Property that defines the optics address.
     *
     * @type {AddressModel}
     */
    this.address = address

    /**
     * Property that defines an array of sellers ids.
     *
     * @usageNotes
     * On firestore, it's a sub-collection named `measurements`.
     *
     * @type {string[]}
     */
    this.sellers = sellers ?? []

    /**
     * Property that defines the optic's admin email.
     *
     * @type {string}
     */
    this.userEmail = userEmail

    /**
     * Property that defines the optic's admin id.
     *
     * @type {string}
     */
    this.adminId = adminId

    /**
     * Property that defines the measurements made by the software partner.
     *
     * @usageNotes
     * On firestore, it's a sub-collection named `measurements`.
     *
     * @type {MeasurementEntity[]}
     */
    this.measurements = measurements ?? []

    /**
     * Property that defines the total measurements done by the whole team.
     *
     * @type {number}
     */
    this.qtyMeasurements = qtyMeasurements ?? 0

    /**
     * Property that defines the total sellers registered by the team.
     *
     * @type {number}
     */
    this.qtySellers = qtySellers ?? 0

    /**
     * Property that defines the status of a seller user.
     *
     * @type {string}
     */
    this.status = status ?? 'pending'

    /**
     * Property that defines the amount of measurements that an optic is allowed
     * to have.
     *
     * @type {number}
     */
    this.allowedMeasurements = allowedMeasurements ?? 0

    /**
     * Property that defines the amount of available measurements.
     *
     * @type {number}
     */
    this.availableMeasurements = availableMeasurements ?? 0

    /**
     * Property that defines the optic active sellers.
     *
     * @type {number}
     */
    this.activeSellers = activeSellers ?? 0

    /**
     * Property that defines the optic CNPJ.
     *
     * @type {string}
     */
    this.cnpj = cnpj ?? null

    /**
     * Property that defines an array of credits.
     *
     * @type {CreditEntity[]}
     */
    this.creditHistory = creditHistory ?? []
  }

  /**
   * Gets an object that represents the optic properties.
   */
  toFirestore() {
    return {
      lastMeasurementId: this.lastMeasurementId ?? 0,
      nameRegistered: this.nameRegistered,
      nameFantasy: this.nameFantasy,
      userEmail: this.userEmail,
      active: this.active,
      logo: this.logo,
      adminId: this.adminId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
      deletedBy: this.deletedBy,
      cnpj: this.cnpj,
      qtyMeasurements: this.qtyMeasurements,
      qtySellers: this.qtySellers,
      status: this.status,
      allowedMeasurements: this.allowedMeasurements,
      availableMeasurements: this.availableMeasurements,
      activeSellers: this.activeSellers,
      address: {
        ...this.address,
      },
      creditHistory: this.creditHistory.map((c) => c.toFirestore()),
    }
  }

  /**
   * Converts the firestore data to the optic model.
   *
   * @param {OpticEntity} data the firestore optic data.
   */
  static fromFirestore(data) {
    return new OpticEntity({
      ...data,
      address: AddressModel.fromMap(data.address),
      creditHistory: CreditEntity.fromFirestoreArray(data.creditHistory),
    })
  }
}
