<template>
  <b-modal
    hide-header-close
    v-model="modal"
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    :title="`${$t('requestAccess.noAccessUserModal.title')} ${
      user ? user.name : ''
    } ${user ? user.lastName : ''}`"
  >
    <b-container fluid>
      <div class="row alert alert-danger" role="alert">
        <div class="col md-1 mb-1 mt-1">
          <div class="d-flex justify-content-center">
            <feather-icon icon="AlertTriangleIcon" size="35" />
          </div>
        </div>
        <div class="col col-md-10 mb-1 mt-1">
          <b> {{ $t('requestAccess.noAccessUserModal.msg') }}</b>
        </div>
      </div>
    </b-container>
    <b-row align-h="end" class="pr-1 mt-2">
      <b-button variant="secondary" @click="redirect()">
        <span>OK</span>

        <feather-icon size="16" icon="LogInIcon" class="ml-50" />
      </b-button>
    </b-row>
  </b-modal>
</template>

<script>
import { BContainer, BModal, BButton, BRow } from 'bootstrap-vue'
import RoleEnum from '../utils/roles'

export default {
  components: {
    BContainer,
    BModal,
    BRow,
    BButton,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      modal: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
  },
  watch: {
    show() {
      this.modal = this.show
    },
  },
  methods: {
    async redirect() {
      this.$store.state.auth.noAccess = false

      if (this.user.role === RoleEnum.OPTIC_ADMIN) {
        return
      }

      this.$router.push('/request-access')
    },
  },
}
</script>

<style>
div.modal[role='dialog'] {
  backdrop-filter: blur(6px);
}
</style>
