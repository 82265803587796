import AddressModel from '../models/address.model'
import Entity from './base.entity'
import { CreditEntity } from './credit.entity'
import LaboratoryEntity from './laboratory.entity'
import MeasurementEntity from './measurement.entity'
import OpticEntity from './optic.entity'

/**
 * Class responsible for keeping the software partner properties.
 */
export default class SoftwarePartnerEntity extends Entity {
  constructor({
    id,
    lastMeasurementId,
    nameRegistered,
    nameFantasy,
    logo,
    address,
    userEmail,
    createdAt,
    updatedAt,
    deletedAt,
    deletedBy,
    cnpj,
    laboratories,
    adminId,
    qtyMeasurements,
    qtyOptics,
    qtyLabs,
    qtySellers,
    myOptics,
    measurements,
    status,
    allowedMeasurements,
    availableMeasurements,
    creditHistory,
  } = {}) {
    super({ id, createdAt, updatedAt, deletedAt, deletedBy })
    /**
     * Property that defines the last measurement id created
     *
     * @type {string}
     */
    this.lastMeasurementId = lastMeasurementId

    /**
     * Property that defines the software partner registered name.
     *
     * @type {string}
     */
    this.nameRegistered = nameRegistered

    /**
     * Property that defines the software partner fantasy name.
     *
     * @type {string}
     */
    this.nameFantasy = nameFantasy

    /**
     * Property that defines the logo image URL.
     *
     * @type {string}
     */
    this.logo = logo ?? ''

    /**
     * Property that defines the software partner address.
     *
     * @type {AddressModel}
     */
    this.address = address

    /**
     * Property that defines an array of laboratories.
     *
     * @type {LaboratoryEntity[]}
     */
    this.laboratories = laboratories

    /**
     * Property that defines the software admin email.
     *
     * @type {string}
     */
    this.userEmail = userEmail

    /**
     * Property that defines the software admin id.
     *
     * @type {string}
     */
    this.adminId = adminId

    /**
     * Property that defines the total measurements done by the whole team.
     *
     * @type {number}
     */
    this.qtyMeasurements = qtyMeasurements || 0

    /**
     * Property that defines the total optics registered by the team.
     *
     * @type {number}
     */
    this.qtyOptics = qtyOptics || 0

    /**
     * Property that defines the total labs registered by the team.
     *
     * @type {number}
     */
    this.qtyLabs = qtyLabs || 0

    /**
     * Property that defines the total sellers registered by the team.
     *
     * @type {number}
     */
    this.qtySellers = qtySellers || 0

    /**
     * Property that list all the software partner labs.
     *
     * @usageNotes
     * Sub-collection laboratories inside firestore.
     *
     * @type {LaboratoryEntity[]}
     */
    this.laboratories = laboratories

    /**
     * Property that defines the optics of the software partner, of all the labs
     *
     * @usageNotes
     * On firestore it is an array of doc ids, but later we can use that to populate with more data if need.
     *
     * @type {string[] | OpticEntity[]}
     */
    this.myOptics = myOptics

    /**
     * Property that defines the measurements made by the software partner itself
     *
     * @usageNotes
     * On firestore it is a sub-collection measurements
     *
     * @type {MeasurementEntity[]}
     */
    this.measurements = measurements

    /**
     * Status of seller user.
     *
     * @type {string}
     */
    this.status = status || 'pending'

    /**
     * Property that defines the amount of measurements that a software
     * partner is allowed to have.
     *
     * @type {number}
     */
    this.allowedMeasurements = allowedMeasurements || 0

    /**
     * Property that defines the amount of available measurements.
     *
     * @type {number}
     */
    this.availableMeasurements = availableMeasurements || 0

    /**
     * Property that defines the software partner CNPJ.
     *
     * @type {string}
     */
    this.cnpj = cnpj ?? null

    /**
     * Property that defines an array of credits.
     *
     * @type {CreditEntity[]}
     */
    this.creditHistory = creditHistory ?? []
  }

  /**
   * Gets an object that represents the software partner properties.
   */
  toFirestore() {
    return {
      lastMeasurementId: this.lastMeasurementId ?? 0,
      nameRegistered: this.nameRegistered,
      nameFantasy: this.nameFantasy,
      userEmail: this.userEmail,
      logo: this.logo,
      adminId: this.adminId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
      deletedBy: this.deletedBy,
      cnpj: this.cnpj,
      qtyMeasurements: this.qtyMeasurements,
      qtyLabs: this.qtyLabs,
      qtyOptics: this.qtyOptics,
      qtySellers: this.qtySellers,
      status: this.status,
      allowedMeasurements: this.allowedMeasurements,
      availableMeasurements: this.availableMeasurements,
      address: {
        ...this.address,
      },
      creditHistory: this.creditHistory.map((c) => c.toFirestore()),
    }
  }

  /**
   * Converts the firestore data to the software partner model.
   *
   * @param {SoftwarePartnerEntity} data the firestore software partner data.
   */
  static fromFirestore(data) {
    return new SoftwarePartnerEntity({
      ...data,
      address: AddressModel.fromMap(data.address),
      creditHistory: CreditEntity.fromFirestoreArray(data.creditHistory),
    })
  }
}
