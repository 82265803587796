import Entity from './base.entity'
import { ISO_to_date, beautyParserHourToString } from '@/utils/dates'

/**
 * @typedef {Object} ImageSize
 * @property {number} width
 * @property {number} height
 * @property {number} imgWidth
 * @property {number} imgHeight
 */

/**
 * @typedef {Object} ImageCanvasSize
 * @property {ImageSize} card
 * @property {ImageSize} frontal
 * @property {ImageSize} lateral
 */

/**
 * Class responsible for keeping the Measure properties.
 */
export default class MeasurementEntity extends Entity {
  constructor({
    id,
    numId,
    frontal_picture,
    marked_frontal_picture,
    marked_side_picture,
    measurement_files,
    measurement_origin,
    measurement_progress,
    medical_prescription,
    results,
    imageCanvasSize,
    side_picture,
    card_picture,
    consumer,
    serviceOrder,
    createdAt,
    updatedAt,
    created_at,
  } = {}) {
    super({ id, createdAt, updatedAt })

    /**
     * Property that defines the numeric identifier
     *
     * @type {number}
     */
    this.numId = numId

    /**
     * Property that defines the frontal picture.
     *
     * @type {string}
     */
    this.frontal_picture = frontal_picture

    /**
     * Property that defines the card picture.
     *
     * @type {string}
     */
    this.card_picture = card_picture

    /**
     * Property that defines the marked frontal picture.
     *
     * @type {string}
     */
    this.marked_frontal_picture = marked_frontal_picture

    /**
     * Property that defines the marked side picture.
     *
     * @type {string}
     */
    this.marked_side_picture = marked_side_picture

    /**
     * Property that defines the measurement files.
     *
     * @type {Object[]}
     */
    this.measurement_files = measurement_files

    /**
     * Property that defines the measurement origin.
     *
     * @type {Object[]}
     */
    this.measurement_origin = measurement_origin

    /**
     * Property that defines the measurement progress.
     *
     * @type {Object}
     */
    this.measurement_progress = measurement_progress

    /**
     * Property that defines the medical prescription.
     *
     * @type {string}
     */
    this.medical_prescription = medical_prescription

    /**
     * Property that defines the results.
     *
     * @type {Object}
     */
    this.results = results

    /**
     * Property that defines the image original size.
     *
     * @type {ImageCanvasSize}
     */
    this.imageCanvasSize = imageCanvasSize

    /**
     * Property that defines the linked side_picture id.
     *
     * @type {string}
     */
    this.side_picture = side_picture

    /**
     * Property that defines the measurement consumer name.
     *
     * @type {string}
     */
    this.consumer = consumer ?? ''

    /**
     * Property that defines the measurement service order.
     *
     * @type {string}
     */
    this.serviceOrder = serviceOrder ?? ''

    /**
     * Property that defines the created_at date.
     *
     * @type {string}
     */
    this.created_at = created_at
  }

  /**
   * Gets an object that represents the Measure properties.
   */
  toFirestore() {
    return {
      id: this.id,
      numId: this.numId,
      frontal_picture: this.frontal_picture,
      card_picture: this.card_picture,
      marked_frontal_picture: marked_frontal_picture,
      marked_side_picture: this.marked_side_picture,
      measurement_files: this.measurement_files,
      measurement_origin: this.measurement_origin,
      measurement_progress: this.measurement_progress,
      medical_prescription: this.medical_prescription,
      medical_prescription: this.medical_prescription,
      results: this.results,
      imageCanvasSize: this.imageCanvasSize,
      side_picture: this.side_picture,
      consumer: this.consumer,
      serviceOrder: this.serviceOrder,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      created_at: this.created_at,
    }
  }

  /**
   * Converts the firestore data to the Measure model.
   *
   * @param {MeasurementEntity} data the firestore Measure data.
   */
  static fromFirestore(data) {
    return new MeasurementEntity({
      ...data,
    })
  }

  toString() {
    if (this.results){
      let results = this.results
      return `${
      this.consumer ? `Consumidor - ${this.consumer}\n`:''
      }${
      this.serviceOrder ? `Ordem de serviço - ${this.serviceOrder}\n`:''
      }${
      this.createdAt ? `Resultados - ${ISO_to_date(this.createdAt)}` : ''
      }${
      this.createdAt ? `/ ${beautyParserHourToString(this.createdAt)}` : ''
      }${
        results && results.dnp_card ? `\nMedição com cartão de crédito - ` : `\nMedição com óculos - `
      }${
        this.id ? `${this.id}` : ''
      }${
        results && results.dnp_card
          ? `\n${
              results.dnp_card.od ? 'OD: ' + results.dnp_card.od : ''
            }\n${results.dnp_card.oe ? 'OE: ' + results.dnp_card.oe : ''}`
          : ''
      }${
        results && results.dnp_ponte
          ? `\nVisão simples\nDNP\n${
              results.dnp_ponte.od ? 'OD: ' + results.dnp_ponte.od : ''
            }\n${
              results.dnp_ponte.oe && results.dnp_ponte.od
                ? 'DP: ' +
                  (Number(results.dnp_ponte.oe) + Number(results.dnp_ponte.od))
                : ''
            }\n${results.dnp_ponte.oe ? 'OE: ' + results.dnp_ponte.oe : ''}`
          : ''
      }${
        results && results.dnp_aro
          ? `\nLentes progressivas\nDNP\n${
              results.dnp_aro.od ? 'OD: ' + results.dnp_aro.od : ''
            }\n${
              results.dnp_aro.oe && results.dnp_aro.od
                ? 'DP: ' +
                  (Number(results.dnp_aro.oe) + Number(results.dnp_aro.od))
                : ''
            }\n${results.dnp_aro.oe ? 'OE: ' + results.dnp_aro.oe : ''}`
          : ''
      }${
        results && results.altura_de_montagem
          ? `\nAltura de montagem\n${
              results.altura_de_montagem.od
                ? 'OD: ' + results.altura_de_montagem.od
                : ''
            }\n${
              results.altura_de_montagem.oe
                ? 'OE: ' + results.altura_de_montagem.oe
                : ''
            }`
          : ''
      }${
        results && results.diametro_da_lente
          ? `\nDiâmetro da lente\n${
              results.diametro_da_lente.od
                ? 'OD: ' + results.diametro_da_lente.od
                : ''
            }\n${
              results.diametro_da_lente.oe
                ? 'OE: ' + results.diametro_da_lente.oe
                : ''
            }`
          : ''
      }${
        results && results.angulo_pantoscopico
          ? `\nÂngulo Pantoscópico\nGraus: ${results.angulo_pantoscopico}°`
          : ''
      }${
        results && results.tamanho_aro
          ? `\nTamanho do aro\n${
              results.tamanho_aro.largura
                ? 'Largura: ' + results.tamanho_aro.largura
                : ''
            }\n${
              results.tamanho_aro.altura
                ? 'Altura: ' + results.tamanho_aro.altura
                : ''
            }\n`
          : ''
      }${
        this.measurement_progress.cursors.ponte 
        ? '\nPonte: ' + this.measurement_progress.cursors.ponte 
        : ''
      }`
    }

  }
}
