import Entity from './base.entity'

/**
 * Class responsible for keeping the seller properties.
 */
export default class SellerEntity extends Entity {
  constructor({
    id,
    lastMeasurementId,
    nameRegistered,
    nameFantasy,
    userEmail,
    createdAt,
    updatedAt,
    deletedAt,
    deletedBy,
    status,
    adminId,
    qtyMeasurements,
    allowedMeasurements,
  } = {}) {
    super({ id, createdAt, updatedAt, deletedAt, deletedBy })
    /**
     * Property that defines the last measurement id created
     *
     * @type {string}
     */
    this.lastMeasurementId = lastMeasurementId

    /**
     * Property that defines the seller registered name.
     *
     * @type {string}
     */
    this.nameRegistered = nameRegistered

    /**
     * Property that defines the seller fantasy name.
     *
     * @type {string}
     */
    this.nameFantasy = nameFantasy

    /**
     * Property that defines the seller user email.
     *
     * @type {string}
     */
    this.userEmail = userEmail

    /**
     * Property that defines the seller user id.
     *
     * @type {string}
     */
    this.adminId = adminId

    /**
     * Status of seller user
     *
     * @type {string}
     */
    this.status = status || 'pending'

    /**
     * Property that defines the measurements made by the software partner itself
     *
     * @usageNotes
     * On firestore it is a sub-collection measurements.
     *
     * @type {number}
     */
    this.qtyMeasurements = qtyMeasurements || 0

    /**
     * Property that defines the allowed measurements given by the parent
     *
     * @usageNotes
     * On firestore it is a sub-collection measurements.
     *
     * @type {number}
     */
    this.allowedMeasurements = allowedMeasurements || 0
  }

  /**
   * Gets an object that represents the seller properties.
   */
  toFirestore() {
    return {
      lastMeasurementId: this.lastMeasurementId ?? 0,
      nameRegistered: this.nameRegistered,
      nameFantasy: this.nameFantasy,
      userEmail: this.userEmail,
      adminId: this.adminId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
      deletedBy: this.deletedBy,
      status: this.status,
      qtyMeasurements: this.qtyMeasurements,
      allowedMeasurements: this.allowedMeasurements,
    }
  }

  /**
   * Converts the firestore data to the seller model.
   *
   * @param {SellerEntity} data the firestore seller data.
   */
  static fromFirestore(data) {
    return new SellerEntity({
      ...data,
    })
  }
}
