import { QueryConstraint } from 'firebase/firestore'
import { Store } from 'vuex'

import InvoiceController from '@/controllers/invoice.controller'
import InvoiceEntity from '@/entities/invoice.entity'

const invoiceController = new InvoiceController()

export default {
  state: {
    invoices: [],
  },
  getters: {},
  mutations: {
    SET_INVOICES(state, data) {
      state.invoices = data
    },
  },
  actions: {
    async fetchOneUserInvoice({ commit }, id) {
      let invoice = null
      try {
        invoice = await invoiceController.getInvoices(id)
        commit('SET_INVOICES', invoice)
      } catch (e) {
        console.log(e)
      }
      return invoice
    },
  },
}
