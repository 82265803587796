import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QueryConstraint,
  QuerySnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from 'firebase/storage'

import { db, storage } from '../api/firebase'

/**
 * Class responsible for keeping the base methods for all controllers.
 */
export default class Controller {
  /**
   * Gets a query snapshot from the given path according to the given
   * constraints.
   *
   * @param {string[]} path the collection path to be accessed.
   * @param {QueryConstraint[]} constraints the constraints to be applied to the request.
   * @throws a Firebase exception.
   */
  async getAll(path, constraints = []) {
    return getDocs(query(collection(db, ...path), ...constraints))
  }

  /**
   * Gets a document data by its id and path.
   *
   * @param {string[]} path the document path to be accessed.
   * @throws a Firebase exception.
   */
  async getById(path) {
    return getDoc(doc(db, ...path))
  }

  /**
   * Listens for changes in a certain document according to the given path.
   *
   * @param {string[]} path the document path to be accessed.
   * @param {(snapshot: DocumentSnapshot<DocumentData>) => void} callback a function to be called when the document triggers.
   */
  async listen(path, callback) {
    const ref = doc(db, ...path)

    onSnapshot(ref, callback)
  }

  /**
   * Listens for changes in a collection according to the given path.
   *
   * @param {string[]} path the collection path to be accessed.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback a function to be called when the document triggers.
   */
  async listenMultiple(path, callback) {
    const q = query(collection(db, ...path))

    onSnapshot(q, callback)
  }

  /**
   * Adds a new document into the given path with the given data in it.
   *
   * @param {string[]} path the collection path to be accessed.
   * @param {Record<string, unknown>} data the data to be added.
   * @throws a Firebase exception.
   */
  async create(path, data) {
    const docData = { ...data }

    if (docData.id) {
      delete docData.id
    }

    return addDoc(collection(db, ...path), docData)
  }

  /**
   * Updates a document from the given document path with the given data.
   *
   * @param {string[]} path the document path to be accessed.
   * @param {Record<string, unknown>} data the data to be added.
   * @throws a Firebase exception.
   */
  async update(path, data) {
    const docData = { ...data }

    if (docData.id) {
      delete docData.id
    }

    return updateDoc(doc(db, ...path), docData)
  }

  /**
   * Sets a document data according to its id and path.
   *
   * @param {string[]} path the collection path to be accessed.
   * @param {Record<string, unknown>} data the data to be set.
   * @throws a Firebase exception.
   */
  async setDataById(path, data) {
    const docData = { ...data }

    if (docData.id) {
      delete docData.id
    }

    return setDoc(doc(db, ...path), docData)
  }

  /**
   * Deletes a document from the given collection path according to its id.
   *
   * @param {string} path the collection path to be accessed.
   */
  async delete(path) {
    return deleteDoc(doc(db, path))
  }

  /**
   * Gets a document snapshot by its reference.
   *
   * @param {DocumentReference} ref the document reference.
   * @throws a Firebase exception.
   */
  async getWithRef(ref) {
    return getDoc(ref)
  }

  /**
   * Gets an image URL from the storage.
   *
   * @param {string} path the storage child path.
   * @return the image URL.
   */
  async getImageUrl(path) {
    return getDownloadURL(ref(storage, path))
  }

  /**
   * Uploads a file to the Firebase storage.
   *
   * @param {string} path the storage child path.
   * @param {File} file the file to be uploaded.
   */
  async uploadFile(path, file) {
    await uploadBytes(ref(storage, path), file)
  }

  /**
   * Deletes a file from the Firebase storage.
   *
   * @param {string} path the storage child path.
   */
  async deleteFile(path) {
    await deleteObject(ref(storage, path))
  }
}
