import Entity from './base.entity'

/**
 * Class that represents the external plan entity.
 */
export default class PlanEntity extends Entity {
  constructor({
    id,
    createdAt,
    updatedAt,
    name,
    measurements,
    sellers,
    type,
  } = {}) {
    super({ id, createdAt, updatedAt })

    /**
     * Property that defines the plan name.
     *
     * @type {name}
     */
    this.name = name

    /**
     * Property that defines the plan measurements amount.
     *
     * @type {number}
     */
    this.measurements = measurements

    /**
     * Property that defines the plan sellers amount.
     *
     * @type {number}
     */
    this.sellers = sellers

    /**
     * Property that defines the plan type. (eg. optic).
     *
     * @type {string}
     */
    this.type = type
  }

  /**
   * Gets an object that represents the current plan properties.
   */
  toFirestore() {
    return {
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      name: this.name,
      measurements: this.measurements,
      sellers: this.sellers,
      type: this.type,
    }
  }

  /**
   * Gets a new plan entity according to the given data.
   *
   * @param {PlanEntity} data the plan entity data.
   */
  static fromFirestore(data) {
    return new PlanEntity(data)
  }
}
