/**
 * Class that represents the base entity, used by all entities
 * that is stored into the database.
 */
export default class Entity {
  constructor({ id, createdAt, updatedAt, deletedAt, deletedBy } = {}) {
    /**
     * Property that defines the entity document id.
     *
     * @type {string}
     */
    this.id = id

    /**
     * Property that defines the entity creation date.
     *
     * @type {string}
     */
    this.createdAt = createdAt || null

    /**
     * Property that defines the entity edition date.
     *
     * @type {string}
     */
    this.updatedAt = updatedAt || null

    /**
     * Property that defines the entity deletion date.
     *
     * @type {string}
     */
    this.deletedAt = deletedAt || null

    /**
     * Property that defines the document path of who deleted the entity.
     *
     * @type {string}
     */
    this.deletedBy = deletedBy || null
  }
}
