/**
 * Class that represents the reason enum, which contains all possible
 * reasons for credit transactions.
 */
export default class ReasonEnum {
  /**
   * assignment
   */
  static ASSIGNMENT = 'assignment'

  /**
   * received
   */
  static RECEIVED = 'received'

  /**
   * removed
   */
  static REMOVED = 'removed'

  /**
   * contract
   */
  static CONTRACT = 'contract'

  /**
   * renovation
   */
  static RENOVATION = 'renovation'

  /**
   * unknown
   */
  static UNKNOWN = 'unknown'

  /**
   * not-informed
   */
  static NOT_INFORMED = 'not-informed'
}
