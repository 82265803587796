import { QueryConstraint } from 'firebase/firestore'
import UserEntity from '../entities/user.entity'
import Controller from './base.controller'
import { callHttpsCallableFunction } from '../api/firebase'
import { cF } from '../utils/cloud-functions'

const collection = 'users'

/**
 * @typedef {Object} EmailData
 * @property {string} company
 * @property {string} paymentUrl
 * @property {string} toEmail
 * @property {string} subject
 * @property {string} name
 * @property {string} fromEmail
 */

/**
 * Class responsible for keeping the user data management.
 */
export default class UserController extends Controller {
  /**
   * Creates an user into the database.
   *
   * @param {UserEntity} data the user data to be created.
   * @returns an user entity.
   * @throws a Firebase exception.
   */
  async create(data) {
    const result = await super.create([collection], data)

    return new UserEntity({ ...data, id: result.id })
  }

  /**
   * Gets all application users according to the given constraints.
   *
   * @param {QueryConstraint[]} constraints the query filters.
   * @returns an array of users.
   * @throws a Firebase exception.
   */
  async getAll(constraints) {
    const snapshot = await super.getAll([collection], constraints)

    return snapshot.docs.map((u) =>
      UserEntity.fromFirestore({ ...u.data(), id: u.id }),
    )
  }

  /**
   * Listens for changes in the users collection.
   *
   * @param {(users: UserEntity[]) => void} callback a callback to be called when the collection updates.
   */
  listenMultiple(callback) {
    super.listenMultiple([collection], (snap) => {
      if (snap.empty) {
        return void callback([])
      }

      const docs = snap.docs.map((u) =>
        UserEntity.fromFirestore({ ...u.data(), id: u.id }),
      )

      callback(docs)
    })
  }

  /**
   * Gets one user from the database.
   *
   * @param {string} id the user id.
   * @returns an user entity.
   * @throws a Firebase exception.
   */
  async getById(id) {
    const user = await super.getById([collection, id])

    return UserEntity.fromFirestore({ ...user.data(), id })
  }

  /**
   * Updates an user from the database.
   *
   * @param {string} id the user id.
   * @param {UserEntity} data the data to be updated.
   * @returns an user entity.
   */
  async update(id, data) {
    await super.update([collection, id], data.toFirestore())

    return new UserEntity({ ...data, id })
  }

  /**
   * Deletes a user from the database.
   *
   * @param {string} id the user id.
   * @throws a Firebase exception.
   */
  async delete(id) {
    await super.delete(`${collection}/${id}`)
  }

  /**
   * Sends the payment email to an user.
   *
   * @param {EmailData} data an object that contains the email information.
   */
  async sendPaymentEmail(data) {
    return callHttpsCallableFunction(cF.sendEmail, {
      data: data,
      template: 'stripe-payment',
    })
  }

  /**
   * Sends the access granting email to an user.
   *
   * @param {EmailData} data an object that contains the email information.
   */
  async sendAccessGrantedEmail(data) {
    return callHttpsCallableFunction(cF.sendEmail, {
      data: data,
      template: 'access-granted',
    })
  }

  /**
   * Sends the external plan update email to an user.
   *
   * @param {EmailData} data an object that contains the email information.
   */
  async sendUpdateExternalPlanEmail(data) {
    return callHttpsCallableFunction(cF.sendEmail, {
      data: data,
      template: 'update-external-plan',
    })
  }

  /**
   * Sends the welcoming message email to an user.
   *
   * @param {EmailData} data an object that contains the email information.
   */
  async sendWelcomingMessageEmail(data) {
    return callHttpsCallableFunction(cF.sendEmail, {
      data: data,
      template: 'welcoming-message',
    })
  }

  /**
   * Sends the account blocking email to an user.
   *
   * @param {EmailData} data an object that contains the email information.
   */
  async sendAccountBlockedEmail(data) {
    return callHttpsCallableFunction(cF.sendEmail, {
      data: data,
      template: 'account-blocked',
    })
  }

  /**
   * Sends the account reactivating email to an user.
   *
   * @param {EmailData} data an object that contains the email information.
   */
  async sendAccountReactivatedEmail(data) {
    return callHttpsCallableFunction(cF.sendEmail, {
      data: data,
      template: 'account-reactived',
    })
  }

  /**
   * Sends the purchase confirmation email to an user.
   *
   * @param {EmailData} data an object that contains the email information.
   */
  async sendPurchaseConfirmationEmail(data) {
    return callHttpsCallableFunction(cF.sendEmail, {
      data: data,
      template: 'purchase-confirmation',
    })
  }

  /**
   * Soft deletes an user from the database.
   *
   * @param {string} path the user document path.
   * @param {string} by a path to the user responsible for soft deleting the user.
   */
  async softDelete(path, by) {
    await super.update(path.split('/'), {
      deletedAt: new Date().toISOString(),
      deletedBy: by,
    })
  }
  /**
   * Restores an user from a soft delete.
   *
   * @param {string} path the user document path.
   */
  async restore(path) {
    await super.update(path.split('/'), {
      deletedAt: null,
      deletedBy: null,
    })
  }
}
