import SoftwarePartnerEntity from '../entities/software-partner.entity'

import Controller from './base.controller'

const collection = 'software-partners'

/**
 * Class responsible for keeping all the software partner logic.
 */
export default class SoftwarePartnerController extends Controller {
  /**
   * Gets all software partner from the database.
   *
   * @param {QueryConstraint[]} constraints the constraints to be applied to the request.
   * @returns an array of software partner.
   */
  async getAll(constraints = []) {
    const snapshot = await super.getAll([collection], constraints)

    return snapshot.docs.map((doc) =>
      SoftwarePartnerEntity.fromFirestore({ ...doc.data(), id: doc.id }),
    )
  }

  /**
   * Gets a software partner according to its id.
   *
   * @param {string} path the software partner document path.
   * @returns a software partner object.
   */
  async getOne(path) {
    const p = path.split('/').filter((el) => !!el)

    const doc = await super.getById(p)
    return SoftwarePartnerEntity.fromFirestore({ ...doc.data(), id: doc.id })
  }

  /**
   * Listens for changes in a specific software partner.
   *
   * @param {string} path the software partner document path.
   * @param {(softwarePartner?: SoftwarePartnerEntity) => void} callback a callback to be called when the software partner is triggered.
   */
  listenOne(path, callback) {
    super.listen(path.split('/'), (document) => {
      if (!document.exists()) {
        return void callback(null)
      }

      callback(
        SoftwarePartnerEntity.fromFirestore({
          ...document.data(),
          id: document.id,
        }),
      )
    })
  }

  /**
   * Creates a software partner into the database.
   *
   * @param {SoftwarePartnerEntity} data the software partner data.
   * @returns a software partner with its id.
   */
  async create(data) {
    data = new SoftwarePartnerEntity(data)

    const result = await super.create([collection], data.toFirestore())

    return {
      data: new SoftwarePartnerEntity({ ...data, id: result.id }),
      relationPath: result.path,
    }
  }

  /**
   * Updates a software partner from the database with the given data.
   *
   * @param {SoftwarePartnerEntity} data the software partner data.
   * @param {string} id the software partner id.
   * @returns a software partner with its id.
   */
  async update(data, id) {
    await super.update([collection, id], data.toFirestore())

    return new SoftwarePartnerEntity({ ...data, id: id })
  }

  /**
   * Deletes a software partner from the database.
   *
   * @param {string} path the software partner path.
   */
  async delete(path) {
    await super.delete(path)
  }

  /**
   * Soft deletes a software partner from the database.
   *
   * @param {string} path the software partner document path.
   * @param {string} by a path to the user responsible for soft deleting the software partner.
   */
  async softDelete(path, by) {
    await super.update(path.split('/'), {
      deletedAt: new Date().toISOString(),
      deletedBy: by,
    })
  }

  /**
   * Restores a software partner from a soft delete.
   *
   * @param {string} path the software partner document path.
   */
  async restore(path) {
    await super.update(path.split('/'), {
      deletedAt: null,
      deletedBy: null,
    })
  }
}
