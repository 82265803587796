/**
 * Property that keeps the name of all months.
 *
 * It may be used with i18n.
 */
export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

/**
 * Gets a formatted string that represents the date according
 * to the given format.
 *
 * @param {Date|string} date the date to be parsed.
 * @param {string} format the string format that the date will be parsed to. Defaults to 'mm/dd/yyyy'.
 * @param {boolean} numeric whether the month will be numeric or written. Defaults to false.
 * @returns {string} the parsed date.
 *
 * @example
 * ```
 * parseDateToString(new Date('2022-03-11'), 'mm dd yyyy', false) => 'Mar 11 2022'
 * parseDateToString(new Date('2022-03-11'), 'mm/dd/yyyy', true) => '03/11/2022'
 * parseDateToString('2022-03-11', 'mm/dd/yyyy') => 'Mar/11/2022'
 * ```
 */
export function parseDateToString(date, format = 'mm/dd/yyyy', numeric) {
  const nDate = new Date(date)

  const day = nDate.getDate()
  const month = nDate.getMonth()
  const year = nDate.getFullYear()

  return format
    .replace('dd', day.toString().padStart(2, '0'))
    .replace(
      'mm',
      numeric ? (month + 1).toString().padStart(2, '0') : months[month],
    )
    .replace('yyyy', year)
}

/**
 * Gets a time string according to the given timestamp.
 *
 * @param {number} timestamp a date timestamp in seconds.
 * @returns a formatted time string.
 */
export function fromTimestampToString(timestamp) {
  const nDate = new Date(timestamp * 1000)

  const offset = nDate.getTimezoneOffset()
  const timezonedDate = new Date(nDate.getTime() - offset * 60 * 1000)

  const beautyDate = timezonedDate.toLocaleString(this.$i18n.locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })

  const hours = `${timezonedDate.getHours()}`.padStart(2, '0')
  const minutes = `${timezonedDate.getMinutes()}`.padStart(2, '0')

  return `${beautyDate} - ${hours}:${minutes}`
}

/**
 * Gets a date string according to the given timestamp.
 *
 * @param {number} timestamp a date timestamp in seconds.
 * @returns a formatted date string.
 */
export function fromTimestampToStringDateOnly(timestamp) {
  const nDate = new Date(timestamp * 1000)

  const offset = nDate.getTimezoneOffset()
  const timezonedDate = new Date(nDate.getTime() - offset * 60 * 1000)

  return timezonedDate.toLocaleString(this.$i18n.locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

/**
 * Gets a formatted time string according to the given date.
 *
 * @example
 * ```
 * '11:12'
 * ```
 *
 * @param {string | number | Date} date any date format.
 * @returns a formatted date string.
 */
export function beautyParserHourToString(date) {
  const nDate = new Date(date)
  // const offset = nDate.getTimezoneOffset()
  const timezonedDate = new Date(nDate.getTime())

  const hours = `${timezonedDate.getHours()}`.padStart(2, '0')
  const minutes = `${timezonedDate.getMinutes()}`.padStart(2, '0')

  return hours + ':' + minutes
}

/**
 * Gets a formatted date local string according to the given date.
 *
 * @example
 * ```
 * '17/01/2023'
 * ```
 *
 * @param {string | number | Date} date any date format.
 * @returns a formatted date string.
 */
export function beautyParserDateToString(date) {
  const nDate = new Date(date)
  // const offset = nDate.getTimezoneOffset()
  const timezonedDate = new Date(nDate.getTime())

  return timezonedDate.toLocaleString(this.$i18n.locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

/**
 * Gets a date string (yyyy-mm-dd) according to the given ISO string.
 *
 * @param {string} date a date ISO string.
 * @returns a date string.
 */
export function ISO_to_date(date) {
  return !date ? '' : date.substring(0, 10)
}

/**
 * Gets a time string (HH:mm) according to the given ISO string.
 *
 * @param {string} date a date ISO string.
 * @returns a time string.
 */
export function ISO_to_time(date) {
  return !date ? '' : date.slice(11, -8)
}
