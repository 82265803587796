<template>
  <b-modal
    hide-header-close
    v-model="modal"
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    :title="`${$t('requestAccess.unallowedUserModal.title')} ${
      user ? user.name : ''
    } ${user ? user.lastName : ''}`"
  >
    <b-container fluid>
      <div class="row alert alert-warning" role="alert">
        <div class="col md-1 mb-1 mt-1">
          <div class="d-flex justify-content-center">
            <feather-icon icon="ClockIcon" size="35" />
          </div>
        </div>
        <div class="col col-md-10 mb-1 mt-1">
          <b> {{ $t('requestAccess.unallowedUserModal.msg') }}</b>
        </div>
      </div>
    </b-container>
    <b-row align-h="end" class="pr-1 mt-2">
      <b-button variant="secondary" @click="logout()"
        ><feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>{{ $t('logout') }}</span></b-button
      >
    </b-row>
  </b-modal>
</template>

<script>
import { BContainer, BModal, BButton, BRow } from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BModal,
    BRow,
    BButton,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      modal: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
  },
  watch: {
    show() {
      this.modal = this.show
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout')
      this.$router.push('/')
    },
  },
}
</script>
