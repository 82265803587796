import Entity from './base.entity'

/**
 * Class that represents the invoice entity.
 */
export default class InvoiceEntity extends Entity {
  constructor({
    id,
    createdAt,
    updatedAt,
    number,
    lines,
    period_start,
    period_end,
    status,
    total,
    invoice_pdf,
  } = {}) {
    super({ id, createdAt, updatedAt })

    /**
     * Property that defines the invoice number.
     *
     * @type {number}
     */
    this.number = number

    /**
     * Property that defines the invoice lines.
     *
     * @type {Object}
     */
    this.lines = lines

    /**
     * Property that defines the period start timestamp.
     *
     * @type {number}
     */
    this.period_start = period_start

    /**
     * Property that defines the period end timestamp.
     *
     * @type {number}
     */
    this.period_end = period_end

    /**
     * Property that defines the invoice status.
     *
     * @type {string}
     */
    this.status = status

    /**
     * Property that defines the invoice total price.
     *
     * @type {number}
     */
    this.total = total

    /**
     * Property that defines the invoice PDF URL.
     *
     * @type {string}
     */
    this.invoice_pdf = invoice_pdf
  }

  /**
   * Gets a new invoice entity according to the given data.
   *
   * @param {InvoiceEntity} data the invoice data.
   */
  static fromFirestore(data) {
    return new InvoiceEntity(data)
  }
}
