import AddressModel from '../models/address.model'
import Entity from './base.entity'
import { CreditEntity } from './credit.entity'
import MeasurementEntity from './measurement.entity'

/**
 * Class responsible for keeping the laboratory properties.
 */
export default class LaboratoryEntity extends Entity {
  constructor({
    id,
    lastMeasurementId,
    nameRegistered,
    nameFantasy,
    logo,
    address,
    userEmail,
    createdAt,
    updatedAt,
    deletedAt,
    deletedBy,
    cnpj,
    adminId,
    qtyMeasurements,
    qtyOptics,
    qtySellers,
    optics,
    measurements,
    status,
    allowedMeasurements,
    allowedSellers,
    availableMeasurements,
    creditHistory,
  } = {}) {
    super({ id, createdAt, updatedAt, deletedAt, deletedBy })
    /**
     * Property that defines the last measurement id created
     *
     * @type {string}
     */
    this.lastMeasurementId = lastMeasurementId

    /**
     * Property that defines the laboratory registered name.
     *
     * @type {string}
     */
    this.nameRegistered = nameRegistered

    /**
     * Property that defines the laboratory fantasy name.
     *
     * @type {string}
     */
    this.nameFantasy = nameFantasy

    /**
     * Property that defines the logo image URL.
     *
     * @type {string}
     */
    this.logo = logo ?? ''

    /**
     * Property that defines the laboratory address.
     *
     * @type {AddressModel}
     */
    this.address = address

    /**
     * Property that defines an array of optics ids.
     *
     * @type {string[]}
     */
    this.optics = optics

    /**
     * Property that defines the laboratory admin email.
     *
     * @type {string}
     */
    this.userEmail = userEmail

    /**
     * Property that defines the laboratory admin id.
     *
     * @type {string}
     */
    this.adminId = adminId

    /**
     * Property that defines the total measurements done by the whole team
     *
     * @type {number}
     */
    this.qtyMeasurements = qtyMeasurements || 0

    /**
     * Property that defines the total optics registered by the team
     *
     * @type {number}
     */
    this.qtyOptics = qtyOptics || 0

    /**
     * Property that defines the total sellers registered by the team
     *
     * @type {number}
     */
    this.qtySellers = qtySellers || 0

    /**
     * Property that defines the measurements made by the software partner itself
     *
     * @usageNotes
     * On firestore it is a sub-collection measurements
     *
     * @type {MeasurementEntity[]}
     */
    this.measurements = measurements

    /**
     * Status of lab user
     *
     * @type {string}
     */
    this.status = status || 'pending'

    /**
     * Property that defines the amount of measurements that a laboratory is
     * allowed to have.
     *
     * @type {number}
     */
    this.allowedMeasurements = allowedMeasurements || 0

    /**
     * Property that defines the amount of measurements that a laboratory is
     * allowed to have.
     *
     * @type {number}
     */
    this.allowedSellers = allowedSellers || 0

    /**
     * Property that defines the amount of available measurements.
     *
     * @type {number}
     */
    this.availableMeasurements = availableMeasurements || 0

    /**
     * Property that defines the laboratory CNPJ.
     *
     * @type {string}
     */
    this.cnpj = cnpj ?? null

    /**
     * Property that defines an array of credits.
     *
     * @type {CreditEntity[]}
     */
    this.creditHistory = creditHistory ?? []
  }

  /**
   * Gets an object that represents the laboratory properties.
   */
  toFirestore() {
    return {
      lastMeasurementId: this.lastMeasurementId ?? 0,
      nameRegistered: this.nameRegistered,
      nameFantasy: this.nameFantasy,
      userEmail: this.userEmail,
      logo: this.logo,
      adminId: this.adminId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
      deletedBy: this.deletedBy,
      cnpj: this.cnpj,
      qtyMeasurements: this.qtyMeasurements,
      qtyOptics: this.qtyOptics,
      qtySellers: this.qtySellers,
      status: this.status,
      allowedMeasurements: this.allowedMeasurements,
      allowedSellers: this.allowedSellers,
      availableMeasurements: this.availableMeasurements,
      address: {
        ...this.address,
      },
      creditHistory: this.creditHistory.map((c) => c.toFirestore()),
    }
  }

  /**
   * Converts the firestore data to the laboratory model.
   *
   * @param {LaboratoryEntity} data the firestore laboratory data.
   */
  static fromFirestore(data) {
    return new LaboratoryEntity({
      ...data,
      address: AddressModel.fromMap(data.address),
      creditHistory: CreditEntity.fromFirestoreArray(data.creditHistory),
    })
  }
}
