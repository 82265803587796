/**
 * Property that keeps all cloud functions calling names.
 */
export const cF = {
  createSubscriptionProduct: 'createSubscriptionProduct',
  subscribeToProduct: 'createCheckoutSubscriptionSession',
  getAllSubscriptionProducts: 'getAllSubscriptionProducts',
  createCustomer: 'createCustomer',
  getCustomer: 'getCustomer',
  getAllCustomers: 'getAllCustomers',
  computeMeasurement: 'computeMeasurement',
  deleteUser: 'deleteUser',
  sendEmail: 'sendEmail',
  updateSubscription: 'updateSubscription',
  generateApiKey: 'generateApiKey',
  getMyApiKey: 'getMyApiKey',
  revokeApiKey: 'revokeApiKey',
  cancelSubscription: 'cancelSubscription',
  subscribeToExternalPlan: 'subscribeToExternalPlan'
}
