import Controller from '@/controllers/base.controller'
import Entity from '../entities/base.entity'
import UserEntity from '../entities/user.entity'
import RoleEnum from './roles'

/**
 * Checks whether the user has measurements left.
 *
 * @param {Record<string, unknown> & Entity} entity the entity to be checked.
 * @param {UserEntity} user the user data.
 * @returns a boolean that represents whether the user has measurements left.
 */
export async function checkIfEntityHasMeasurements(entity, user) {
  // Check if entity has measurements available
  if (
    (user.role === RoleEnum.OPTIC_ADMIN ||
      user.role === RoleEnum.LABORATORY_ADMIN ||
      user.role === RoleEnum.SOFTWARE_ADMIN) &&
    entity.allowedMeasurements
  ) {
    return entity.availableMeasurements > 0
  }

  if (entity.allowedMeasurements) {
    //seller case
    return entity.qtyMeasurements < entity.allowedMeasurements
  }

  const subscriptionSnap = await new Controller().getAll([
    'customers',
    user.id,
    'subscriptions',
  ])

  if (!subscriptionSnap.size) {
    return user.role === RoleEnum.SUPER_ADMIN
  }

  const subscription = subscriptionSnap.docs.pop().data()
  const product = await new Controller().getWithRef(subscription.prices[0])

  const maxMeasurements = product.data().metadata.allowedMeasurements
  const qtyMeasurements = entity.qtyMeasurements

  return qtyMeasurements < maxMeasurements
}

/**
 * Gets the user maximum allowed measurements.
 *
 * @param {UserEntity} user the user data.
 * @returns {Promise<number | boolean>} the user maximum measurements.
 */
export async function getMeasurementsQuota(user) {
  const subscriptionSnap = await new Controller().getAll([
    'customers',
    user.id,
    'subscriptions',
  ])

  if (!subscriptionSnap.size) {
    return user.role === RoleEnum.SUPER_ADMIN ? 1000000 : 0
  }

  const mySubscription = subscriptionSnap.docs.pop().data()
  const myProduct = await new Controller().getWithRef(mySubscription.prices[0])

  return myProduct.data().metadata.allowedMeasurements
}

/**
 * Checks whether some entity has available sellers.
 *
 * @param {Record<string, unknown> & Entity} entity the entity to be checked.
 * @param {UserEntity} user the user data.
 * @param {number} baseAmount a number that represents a base amount of seller to be counted. Defaults to `0`.
 * @returns a boolean that represents whether the entity has available sellers.
 */
export async function checkIfEntityHasSellersAvailable(
  entity,
  user,
  baseAmount = 0,
) {
  const subscriptionSnap = await new Controller().getAll([
    'customers',
    user.id,
    'subscriptions',
  ])

  if (!subscriptionSnap.size) {
    return user.role === RoleEnum.SUPER_ADMIN
  }

  const subscription = subscriptionSnap.pop().data()
  const product = await new Controller().getWithRef(subscription.prices[0])

  const maxSellers = product.data().metadata.allowedSellers
  const qtySellers = entity.qtySellers

  return qtySellers + baseAmount < maxSellers
}

/**
 * Gets the amount of available sellers from some user.
 *
 * @param {UserEntity} user the user data
 * @returns {Promise<number>} the amount of available sellers.
 */
export async function getAllowedSellers(user) {
  /**
   * @type {import('firebase/firestore').DocumentData}
   */
  let subscription

  const subscriptionSnap = await new Controller().getAll([
    'customers',
    user.id,
    'subscriptions',
  ])

  if (!subscriptionSnap.size) {
    if (!user.adminDocRef && !user.sellerDocRef) {
      return user.role === RoleEnum.SUPER_ADMIN ? 1000000 : 0
    }

    const docRef = user.adminDocRef || user.sellerDocRef
    const parts = docRef.split('/')

    const docSnap = await new Controller().getById([parts[0], parts[1]])

    if (!docSnap.exists()) {
      return 0
    }

    const snap = await new Controller().getAll([
      'customers',
      docSnap.data().adminId,
      'subscriptions',
    ])

    if (snap.size) {
      subscription = snap.docs.pop().data()
    } else {
      return 0
    }
  } else {
    subscription = subscriptionSnap.docs.pop().data()
  }

  const product = await new Controller().getWithRef(subscription.prices[0])

  return product.data().metadata.allowedSellers
}
