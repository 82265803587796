/**
 * Class responsible for keeping the address properties.
 */
export default class AddressModel {
  constructor({ line1, line2, zipCode, number, country } = {}) {
    /**
     * Property that defines the address main line.
     *
     * @type {string}
     */
    this.line1 = line1 ?? ''

    /**
     * Property that defines the address secondary line.
     *
     * @type {string}
     */
    this.line2 = line2 ?? ''

    /**
     * Property that defines the address zip code.
     *
     * @type {string}
     */
    this.zipCode = zipCode ?? ''

    /**
     * Property that defines the address number.
     *
     * @type {number}
     */
    this.number = number ?? null

    /**
     * Property that defines the address country.
     *
     * @type {string}
     */
    this.country = country ?? ''
  }

  /**
   * Converts a map object to the address model.
   *
   * @param {AddressModel} map the map that represents the address.
   * @returns the converted model.
   */
  static fromMap(map) {
    return new AddressModel({
      line1: map.line1,
      line2: map.line2,
      country: map.country,
      zipCode: map.zipCode,
      number: map.number,
    })
  }
}
