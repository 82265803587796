export default [
  {
    path: '/tomador-de-medidas',
    name: 'Tomador de Medidas',
    component: () => import('@/views/public/Tomador.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },
  {
    path: '/consultor-optico',
    name: 'consultor-optico',
    component: () => import('@/views/public/Consultor.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },
  {
    path: '/tabela-de-lentes',
    name: 'Tabela de Lentes',
    component: () => import('@/views/public/Tabela.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },
]
