import i18n from '@/libs/i18n'

/**
 * Class that represents an enum of user roles.
 */
export default class RoleEnum {
  /**
   * 0
   */
  static USER = 0

  /**
   * 1
   */
  static CUSTOMER = 1

  /**
   * 2
   */
  static SELLER = 2

  /**
   * 3
   */
  static OPTIC_ADMIN = 3

  /**
   * 4
   */
  static LABORATORY_ADMIN = 4

  /**
   * 5
   */
  static SOFTWARE_ADMIN = 5

  /**
   * 6
   */
  static SUPER_ADMIN = 6

  /**
   * Get role as a string.
   *
   * @param {number} role a number that represents an user role.
   */
  static getRoleAsString(role) {
    switch (role) {
      case RoleEnum.USER:
        return 'USER'
      case RoleEnum.CUSTOMER:
        return 'CUSTOMER'
      case RoleEnum.SELLER:
        return 'SELLER'
      case RoleEnum.OPTIC_ADMIN:
        return 'OPTIC ADMIN'
      case RoleEnum.LABORATORY_ADMIN:
        return 'LABORATORY ADMIN'
      case RoleEnum.SOFTWARE_ADMIN:
        return 'SOFTWARE ADMIN'
      case RoleEnum.SUPER_ADMIN:
        return 'SUPER ADMIN'
      default:
        return ''
    }
  }

  /**
   * Gets a list of all main roles (2-6).
   */
  static listRoles() {
    return [
      {
        value: 6,
        label: i18n.t('roles.admin'),
      },
      {
        value: 5,
        label: i18n.t('roles.software-partner'),
      },
      {
        value: 4,
        label: i18n.t('roles.lab'),
      },
      {
        value: 3,
        label: i18n.t('roles.optic'),
      },
      {
        value: 2,
        label: i18n.t('roles.seller'),
      },
    ]
  }

  /**
   * Gets the role title according to the given role.
   *
   * @param {number} role a number that represents an user role.
   * @returns {string} the role title.
   */
  static getRoleTitle(role) {
    switch (role) {
      case RoleEnum.SUPER_ADMIN:
        return i18n.t('roles.admin')
      case RoleEnum.SOFTWARE_ADMIN:
        return i18n.t('roles.sp')
      case RoleEnum.LABORATORY_ADMIN:
        return i18n.t('roles.lab')
      case RoleEnum.OPTIC_ADMIN:
        return i18n.t('roles.optic')
      case RoleEnum.SELLER:
        return i18n.t('roles.seller')
      default:
        return '-'
    }
  }

  /**
   * Gets the role color variant according to the given role.
   *
   * @param {number} role a number that represents an user role.
   */
  static getRoleVariant(role) {
    switch (role) {
      case RoleEnum.SUPER_ADMIN:
        return 'danger'
      case RoleEnum.SOFTWARE_ADMIN:
        return 'warning'
      case RoleEnum.LABORATORY_ADMIN:
        return 'primary'
      case RoleEnum.OPTIC_ADMIN:
        return 'secondary'
      case RoleEnum.SELLER:
        return 'success'
      default:
        return '-'
    }
  }
}
