import { initializeApp } from 'firebase/app'
import { getAuth, ActionCodeSettings } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'
import {
  getFunctions,
  httpsCallable,
  // connectFunctionsEmulator,
} from 'firebase/functions'

export const app = initializeApp({
  apiKey: process.env.VUE_APP_API_KEY,
  appId: process.env.VUE_APP_APP_ID,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
})

/**
 * @param {number} role the number that represents the user role. I.e.: 6 for `superadmin`.
 * @param {string} authLinkId the document id that the user will be related to.
 * @returns {ActionCodeSettings} an object that represents the Firebase action code settings.
 */
export const actionCodeSettings = (authLinkId) => ({
  handleCodeInApp: true,
  url: `${window.location.origin}/sign-up?al=${authLinkId}`,
})

export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)

// These settings may only be used in case Firebase Emulators are being used
// to emulate Functions.
// const functions = getFunctions(app)
// connectFunctionsEmulator(functions, 'localhost', 5001)

/**
 * Calls a Firebase Function according to the given name and data.
 *
 * @param {string} functionName the function that will be called.
 * @param {unknown} data any data that might be passed to the function.
 * @returns a promise that awaits for the function return.
 */
export async function callHttpsCallableFunction(functionName, data) {
  const f = httpsCallable(getFunctions(), functionName)
  return f(data)
}
