//import i18n from '@/libs/i18n'
export default [
  {
    path: '/admin/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    //component: () => import('@/views/error/ComingSoon.vue'),
    meta: {
      pageTitle: 'home',
      breadcrumb: [
        {
          text: 'home',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [2, 3, 4, 5, 6],
    },
  },
  {
    path: '/admin/users',
    name: 'users',
    component: () => import('@/views/admin/users/UsersList.vue'),
    meta: {
      pageTitle: 'users',
      breadcrumb: [
        {
          text: 'users',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [6],
    },
  },
  {
    path: '/admin/users/:id',
    name: 'users-edit',
    component: () => import('@/views/admin/users/UserEdit.vue'),
    meta: {
      pageTitle: 'users',
      breadcrumb: [
        {
          text: 'users',
          active: false,
          to: '/admin/users',
        },
        {
          text: 'usersEdit',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [6],
    },
  },
  {
    path: '/admin/users/view/:id',
    name: 'users-view',
    component: () => import('@/views/admin/users/ViewUser.vue'),
    meta: {
      pageTitle: 'users',
      breadcrumb: [
        {
          text: 'users',
          active: false,
          to: '/admin/users',
        },
        {
          text: 'usersView',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [6],
    },
  },
  {
    path: '/admin/measurements',
    name: 'measurements',
    component: () => import('@/views/admin/measurements/MeasurementsList.vue'),
    meta: {
      pageTitle: 'measurements',
      breadcrumb: [
        {
          text: 'measurements',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [2, 3, 4, 5, 6],
    },
  },
  {
    path: '/admin/measurements/:id',
    name: 'measurement-details',
    component: () =>
      import('@/views/admin/measurements/MeasurementDetails.vue'),
    meta: {
      pageTitle: 'measurementsDetails',
      breadcrumb: [
        {
          text: 'measurements',
          active: false,
          to: '/admin/measurements',
        },
        {
          text: 'measurementsDetails',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [2, 3, 4, 5, 6],
    },
  },
  {
    path: '/admin/stats',
    name: 'stats',
    component: () => import('@/views/admin/stats/Stats.vue'),
    meta: {
      pageTitle: 'statistics',
      breadcrumb: [
        {
          text: 'statistics',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [2, 3, 4, 5, 6],
    },
  },
  {
    path: '/admin/invoices',
    name: 'invoices',
    component: () => import('@/views/admin/planAndInvoices/PlanAndInvoices.vue'),
    meta: {
      pageTitle: 'invoices',
      breadcrumb: [
        {
          text: 'invoices',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [3, 4, 5],
    },
  },
  {
    path: '/admin/software-partners',
    name: 'software-partners',
    component: () =>
      import('@/views/sections/software-partners/SoftwarePartnersList.vue'),
    meta: {
      pageTitle: 'softwarePartners',
      breadcrumb: [
        {
          text: 'softwarePartners',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [6],
    },
  },
  {
    path: '/admin/software-partners/:id',
    name: 'software-partner-edit',
    component: () =>
      import('@/views/sections/software-partners/SoftwarePartnerForm.vue'),
    meta: {
      pageTitle: 'softwarePartners',
      breadcrumb: [
        {
          text: 'softwarePartners',
          active: false,
          to: '/admin/software-partners',
        },
        {
          text: 'form',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [6],
    },
  },
  {
    path: '/admin/laboratories',
    name: 'labs',
    component: () => import('@/views/sections/labs/LabsList.vue'),
    meta: {
      pageTitle: 'laboratories',
      breadcrumb: [
        {
          text: 'laboratories',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [5, 6],
    },
  },
  {
    path: '/admin/laboratories/:id',
    name: 'lab-edit',
    component: () => import('@/views/sections/labs/LabForm.vue'),
    meta: {
      pageTitle: 'laboratories',
      breadcrumb: [
        {
          text: 'laboratories',
          active: false,
          to: '/admin/laboratories',
        },
        {
          text: 'form',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [5, 6],
    },
  },
  {
    path: '/admin/optics',
    name: 'optics',
    component: () => import('@/views/sections/optics/OpticsList.vue'),
    meta: {
      pageTitle: 'optics',
      breadcrumb: [
        {
          text: 'optics',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [4, 5, 6],
    },
  },
  {
    path: '/admin/optics/:id',
    name: 'optic-edit',
    component: () => import('@/views/sections/optics/OpticForm.vue'),
    meta: {
      pageTitle: 'optics',
      breadcrumb: [
        {
          text: 'optics',
          active: false,
          to: '/admin/optics',
        },
        {
          text: 'form',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [4, 5, 6],
    },
  },
  {
    path: '/admin/sellers',
    name: 'sellers',
    component: () => import('@/views/sections/sellers/SellersList.vue'),
    meta: {
      pageTitle: 'sellers',
      breadcrumb: [
        {
          text: 'sellers',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [3, 4, 5, 6],
    },
  },
  {
    path: '/admin/sellers/:id',
    name: 'seller-edit',
    component: () => import('@/views/sections/sellers/SellerForm.vue'),
    meta: {
      pageTitle: 'sellers',
      breadcrumb: [
        {
          text: 'sellers',
          active: false,
          to: '/admin/sellers',
        },
        {
          text: 'form',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [3, 4, 5, 6],
    },
  },
  {
    path: '/admin/plans',
    name: 'plans',
    component: () => import('@/views/admin/plans/PlansList.vue'),
    meta: {
      pageTitle: 'plans',
      breadcrumb: [
        {
          text: 'plans',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [6],
    },
  },
  {
    path: '/admin/plans/:id',
    name: 'plan-edit',
    component: () =>
      import('@/views/admin/plans/PlanForm.vue'),
    meta: {
      pageTitle: 'plans',
      breadcrumb: [
        {
          text: 'plans',
          active: false,
          to: '/admin/plans',
        },
        {
          text: 'form',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [6],
    },
  },
  {
    path: '/admin/external-integrations',
    name: 'external-integrations',
    component: () =>
      import('@/views/admin/integrations/ExternalIntegrations.vue'),
    meta: {
      pageTitle: 'externalIntegrations',
      breadcrumb: [
        {
          text: 'externalIntegrations',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [3, 4, 5, 6],
    },
  },
]
