import AddressModel from '../models/address.model'
import Entity from './base.entity'
import PlanEntity from './plan.entity'

/**
 * Class responsible for keeping the user properties.
 */
export default class UserEntity extends Entity {
  /**
   * Property that defines the user role. It has 7 access level in total:
   * - 0 - user
   * - 1 - customer
   * - 2 - vendor
   * - 3 - opticsAdmin
   * - 4 - labAdmin
   * - 5 - softwareAdmin
   * - 6 - superAdmin
   *
   * @param {number} value
   */
  set role(value) {
    this._role = value < 0 ? 0 : value > 6 ? 6 : value
  }

  /**
   * Property that defines the user role. It has 7 access level in total:
   * - 0 - user
   * - 1 - customer
   * - 2 - vendor
   * - 3 - opticsAdmin
   * - 4 - labAdmin
   * - 5 - softwareAdmin
   * - 6 - superAdmin
   *
   * @type {number}
   */
  get role() {
    return this._role
  }

  constructor({
    id,
    name,
    lastName,
    email,
    role,
    phoneNumber,
    address,
    createdAt,
    updatedAt,
    deletedAt,
    deletedBy,
    cpf,
    //
    adminDocRef,
    sellerOfOpticDocRef,
    sellerDocRef,
    apiKeyId,
    status,
    plan,
  } = {}) {
    super({ id, createdAt, updatedAt, deletedAt, deletedBy })

    /**
     * Property that defines the user first name.
     *
     * @type {string}
     */
    this.name = name

    /**
     * Property that defines the user api key doc id.
     *
     * @type {string}
     */
    this.apiKeyId = apiKeyId

    /**
     * Property that defines the user last name.
     *
     * @type {string}
     */
    this.lastName = lastName

    /**
     * Property that defines the user email.
     *
     * @type {string}
     */
    this.email = email

    /**
     * Property that defines the user role. It has 7 access level in total:
     * - 0 - user
     * - 1 - customer
     * - 2 - vendor
     * - 3 - opticsAdmin
     * - 4 - labAdmin
     * - 5 - softwareAdmin
     * - 6 - superAdmin
     *
     * @type {number}
     * @private
     */
    this._role = role < 0 ? 0 : role > 6 ? 6 : role

    /**
     * Property that defines the user phone number.
     *
     * @type {string}
     */
    this.phoneNumber = phoneNumber ?? ''

    /**
     * Property that defines the user address.
     *
     * @type {AddressModel}
     */
    this.address = address

    /**
     * Property that defines the linked admin doc id.
     *
     * @type {string}
     */
    this.adminDocRef = adminDocRef ?? ''

    /**
     * Property that defines the linked optic the seller works on id.
     *
     * @type {string}
     */
    this.sellerOfOpticDocRef = sellerOfOpticDocRef ?? ''

    /**
     * Property that defines the linked seller doc id.
     *
     * @type {string}
     */
    this.sellerDocRef = sellerDocRef ?? ''

    /**
     * Property that defines the user status.
     *
     * @type {string}
     */
    this.status = status ?? ''

    /**
     * Property that defines the user CPF.
     *
     * @type {string}
     */
    this.cpf = cpf ?? ''

    /**
     * Property that defines the user current subscription plan.
     *
     * @type {PlanEntity}
     */
    this.plan = plan ?? {}
  }

  /**
   * Gets an object that represents the user properties.
   */
  toFirestore() {
    return {
      name: this.name,
      lastName: this.lastName,
      email: this.email,
      role: this.role,
      phoneNumber: this.phoneNumber,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
      deletedBy: this.deletedBy,
      cpf: this.cpf,
      address: {
        line1: this.address.line1,
        line2: this.address.line2,
        zipCode: this.address.zipCode,
        country: this.address.country,
        number: this.address.number,
      },
      adminDocRef: this.adminDocRef,
      sellerOfOpticDocRef: this.sellerOfOpticDocRef,
      sellerDocRef: this.sellerDocRef,
      status: this.status ?? '',
      plan: this.plan ?? {},
    }
  }

  /**
   * Converts the firestore data to the user model.
   *
   * @param {UserEntity} data the firestore user data.
   */
  static fromFirestore(data) {
    return new UserEntity({
      ...data,
      address: AddressModel.fromMap(data.address),
    })
  }
}
