import OpticEntity from '../entities/optic.entity'

import Controller from './base.controller'

const collection = 'optics'

/**
 * Class responsible for keeping all the optics logic.
 */
export default class OpticController extends Controller {
  /**
   * Gets all optics from the database.
   *
   * @param {string} parent the parent path.
   * @returns a list of optics.
   */
  async getAll(parent) {
    const path = parent ? parent.split('/') : []

    if (!path.includes(collection)) {
      path.push(collection)
    }

    const snapshot = await super.getAll(path)

    return snapshot.docs.map((doc) =>
      OpticEntity.fromFirestore({ ...doc.data(), id: doc.id }),
    )
  }

  /**
   * Gets an optic according to its id.
   *
   * @param {string} path the optic document path.
   * @returns an optic entity.
   */
  async getOne(path) {
    const p = path.split('/')

    const doc = await super.getById(p)
    return OpticEntity.fromFirestore({ ...doc.data(), id: doc.id })
  }

  /**
   * Listens for changes in a specific optic.
   *
   * @param {string} path the optic document path.
   * @param {(opticEntity?: OpticEntity) => void} callback a callback to be called when the optic is triggered.
   */
  listenOne(path, callback) {
    super.listen(path.split('/'), (document) => {
      if (!document.exists()) {
        return void callback(null)
      }

      callback(
        OpticEntity.fromFirestore({ ...document.data(), id: document.id }),
      )
    })
  }

  /**
   * Creates an optic into the database.
   *
   * @param {OpticEntity} data the optic data.
   * @param {string} parent the parent path.
   * @returns an optic with its id.
   */
  async create(data, parent) {
    const path = parent ? parent.split('/') : []

    if (!path.includes(collection)) {
      path.push(collection)
    }

    const result = await super.create(path, data.toFirestore())

    return {
      data: new OpticEntity({ ...data, id: result.id }),
      relationPath: result.path,
    }
  }

  /**
   * Updates an optic from the database with the given data.
   *
   * @param {OpticEntity} data the optic data.
   * @param {string} id the optic id.
   * @param {string} parent the parent path.
   * @returns an optic with its id.
   */
  async update(data, id, parent) {
    const path = parent ? parent.split('/') : []

    if (!path.includes(collection)) {
      path.push(collection)
    }

    if (!path.includes(id)) {
      path.push(id)
    }

    await super.update(path, data.toFirestore())
    return new OpticEntity({ ...data, id })
  }

  /**
   * Deletes an optic from the database.
   *
   * @param {string} path the optic document path.
   */
  async delete(path) {
    await super.delete(path)
  }

  /**
   * Soft deletes an optic from the database.
   *
   * @param {string} path the optic document path.
   * @param {string} by a path to the user responsible for soft deleting the optic.
   */
  async softDelete(path, by) {
    await super.update(path.split('/'), {
      deletedAt: new Date().toISOString(),
      deletedBy: by,
    })
  }

  /**
   * Restores an optic from a soft delete.
   *
   * @param {string} path the optic document path.
   */
  async restore(path) {
    await super.update(path.split('/'), {
      deletedAt: null,
      deletedBy: null,
    })
  }
}
