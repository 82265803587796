import { Store } from 'vuex'

import AuthController from '../../controllers/auth.controller'
import UserController from '../../controllers/user.controller'
import AuthLoginModel from '../../models/auth-login.model'
import UserEntity from '../../entities/user.entity'
import AuthLinkEntity from '../../entities/auth-link.entity'
import RoleEnum from '../../utils/roles'
import SoftwarePartnerController from '../../controllers/software-partner.controller'
import LaboratoryController from '../../controllers/laboratory.controller'
import OpticController from '../../controllers/optic.controller'
import SellerController from '../../controllers/seller.controller'
import Controller from '../../controllers/base.controller'
import { getMeasurementsQuota } from '@/utils/plans'

const authController = new AuthController()

export default {
  namespaced: true,
  state: {
    /**
     * Property that defines the logged user data.
     *
     * @type {Object}
     */
    user: null,

    /**
     * Property that defines the user uid.
     *
     * @type {string}
     */
    userId: null,

    /**
     * Property that defines the logged user admin document data.
     *
     * @type {Object}
     */
    userAdminDoc: null,

    /**
     * Property that defines an array which contains the user and parents
     * inactive statuses.
     *
     * @type {string[]}
     */
    inactive: [],

    /**
     * Property that defines whether the application is loading.
     *
     * @type {boolean}
     */
    loading: false,

    /**
     * Property that defines whether the user has no access to the admin panel.
     *
     * @type {boolean}
     */
    noAccess: false,
  },
  getters: {
    /**
     * Indicates whether the used is logged.
     *
     * @param {Object} state the auth state.
     * @returns {boolean} whether the access token is available.
     */
    isAuthenticated: (state) => {
      return !!state.userId
    },
    currentUserRole: (state) => {
      return state.user ? state.user.role : 0
    },
    inactive: (state) => {
      return (
        !!state.inactive.length ||
        (state.user && !!state.user.deletedAt) ||
        state.user.status === 'canceled-subscription' ||
        (state.userAdminDoc && !!state.userAdminDoc.deletedAt)
      )
    },
  },
  mutations: {
    /**
     * Sets the user id into the state.
     *
     * @param {Object} state the auth state
     * @param {string} data the id to be set.
     */
    SET_USER_ID(state, data) {
      state.userId = data
    },

    /**
     * Sets the user data into the state.
     *
     * @param {Object} state the auth state
     * @param {Object} data the user data to be set.
     */
    SET_USER(state, data) {
      state.user = data
    },

    /**
     * Sets the user admin document data into the state.
     *
     * @param {Object} state the auth state
     * @param {Object} data the user admin document data to be set.
     */
    SET_USER_ADMIN_DOC(state, data) {
      state.userAdminDoc = data
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
  },
  actions: {
    /**
     * Sets the user id into the application.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the id to be set.
     */
    setUserId({ commit }, payload) {
      commit('SET_USER_ID', payload)
    },

    /**
     * Sets the user data into the application.
     *
     * @param {Store} store the vuex store.
     * @param {Object} payload the data to be set.
     */
    setUser({ commit }, payload) {
      commit('SET_USER', payload)
    },

    /**
     * Sets the user admin document data into the application.
     *
     * @param {Store} store the vuex store.
     * @param {Object} payload the data to be set.
     */
    setUserAdminDoc({ commit }, payload) {
      commit('SET_USER_ADMIN_DOC', payload)
    },

    /**
     * Signs in an user using its email and password.
     *
     * @param {AuthLoginModel} payload an object containing the user email and password.
     * @returns an object containing the user data or an error.
     */
    async signIn(_, payload) {
      return authController.signIn(payload)
    },

    /**
     * Signs up an user using its email and password.
     *
     * @param {Object} payload an object containing the user data and its password.
     * @param {string} payload.password the user password.
     * @param {UserEntity} payload.user the user data.
     * @returns an object containing the user data or an error.
     */
    async signUp(_, payload) {
      return authController.signUp(payload)
    },

    /**
     * Sends a sign in link to the given email.
     *
     * @param {Object} payload An object containing the user email, document id and role.
     * @param {string} payload.email The email to be used.
     * @param {string} payload.relationPath The document id to be set.
     * @param {number} payload.role The role to be set.
     * @throws {FirebaseException}
     */
    async sendSignInLink(_, payload) {
      await authController.sendSignInLink(payload)
    },

    /**
     * Signs the user in using an email link.
     *
     * @param {Object} payload the action payload.
     * @param {string} payload.email the email to be used.
     * @param {string} payload.link the email link.
     * @throws {FirebaseException}
     */
    async signInWithLink(_, payload) {
      return authController.signInWithLink(payload)
    },

    /**
     * Gets an auth link from the Firestore by its id.
     *
     * @param {string} payload The auth link id.
     * @returns an auth link with its id.
     */
    async getAuthLink(_, payload) {
      return authController.getAuthLink(payload)
    },

    /**
     * Creates an auth link into the Firestore.
     *
     * @param {AuthLink} payload The auth link to be set.
     * @returns an auth link with its id.
     */
    async createAuthLink(_, payload) {
      const now = new Date().toISOString()

      const data = new AuthLinkEntity({
        ...payload,
        createdAt: now,
        updatedAt: now,
      })

      return authController.createAuthLink(data)
    },

    /**
     * Deletes an auth link from the Firestore.
     *
     * @param {string} payload The auth link id.
     */
    async deleteAuthLink(_, payload) {
      await authController.deleteAuthLink(payload)
    },

    /**
     * Verifies whether an OOB code is valid.
     *
     * @param {string} payload the OOB code.
     * @returns {string} the OOB code verification result.
     */
    async verifyOobCode(_, payload) {
      try {
        const result = await authController.verifyOobCode(payload)
        return result.operation === 'EMAIL_SIGNIN' ? 'ok' : 'error'
      } catch (e) {
        return e.code || 'error'
      }
    },

    /**
     * Automatically signs the user in.
     *
     * @param {Store} store the vuex store.
     */
    async autoSignIn({ dispatch }) {
      try {
        const { user } = await authController.autoSignIn()
        if (!user) {
          return
        }

        dispatch('setUserId', user.id)
        dispatch('setUser', user)
        dispatch('loadAdminDocData', user.adminDocRef || user.sellerDocRef)
        dispatch('listenUserChanges')
      } catch (err) {
        return err
      }
    },

    /**
     * Listens for user changes into the database.
     *
     * @param {Store} store the vuex store.
     */
    async listenUserChanges({ state, dispatch }) {
      if (!state.user) {
        return
      }

      const uid = state.userId

      new Controller().listen(['users', uid], (doc) => {
        if (uid !== state.userId) {
          return
        }

        const user = UserEntity.fromFirestore({ ...doc.data(), id: doc.id })
        dispatch('setUser', user)
      })
    },

    /**
     * Loads the current user admin document data.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the document reference/path.
     */
    async loadAdminDocData({ state, dispatch }, payload) {
      dispatch('loadAllParentsStatus')

      if (!state.user || !payload) {
        return
      }

      const uid = state.userId

      switch (state.user.role) {
        case RoleEnum.SOFTWARE_ADMIN:
          new SoftwarePartnerController().listenOne(payload, async (sp) => {
            // if plan parent
            if (payload.split('/').length <= 2) {
              // if missing plan attributes
              if (!sp.allowedMeasurements && !sp.availableMeasurements) {
                const maxMeasurements = await getMeasurementsQuota(state.user)
                sp.availableMeasurements = parseInt(maxMeasurements || 0)
                sp.allowedMeasurements = parseInt(maxMeasurements || 0)
                new SoftwarePartnerController().update(sp, sp.id, payload)
              }
            }

            if (state.userId === uid) {
              dispatch('setUserAdminDoc', sp)
            }
          })
          break
        case RoleEnum.LABORATORY_ADMIN:
          new LaboratoryController().listenOne(payload, async (lab) => {
            // if plan parent
            if (payload.split('/').length <= 2) {
              // if missing plan attributes
              if (!lab.allowedMeasurements && !lab.availableMeasurements) {
                const maxMeasurements = await getMeasurementsQuota(state.user)
                lab.availableMeasurements = parseInt(maxMeasurements || 0)
                lab.allowedMeasurements = parseInt(maxMeasurements) || 0
                new LaboratoryController().update(lab, lab.id, payload)
              }
            }

            if (state.userId === uid) {
              dispatch('setUserAdminDoc', lab)
            }
          })
          break
        case RoleEnum.OPTIC_ADMIN:
          new OpticController().listenOne(payload, async (optic) => {
            // if plan parent
            if (payload.split('/').length <= 2) {
              // if missing plan attributes
              if (!optic.allowedMeasurements && !optic.availableMeasurements) {
                const maxMeasurements = await getMeasurementsQuota(state.user)
                optic.availableMeasurements = parseInt(maxMeasurements || 0)
                optic.allowedMeasurements = parseInt(maxMeasurements || 0)
                new OpticController().update(optic, optic.id, payload)
              }
            }
            if (state.userId === uid) {
              dispatch('setUserAdminDoc', optic)
            }
          })
          break
        case RoleEnum.SELLER:
          new SellerController().listenOne(payload, (seller) => {
            if (state.userId === uid) {
              dispatch('setUserAdminDoc', seller)
            }
          })
          break
      }
    },

    /**
     * Loads the current user and parents statuses.
     *
     * @param {Store} store the vuex store.
     */
    async loadAllParentsStatus({ state }) {
      if (!state.user) {
        return
      }

      // laboratories/jkqpULiCLsztSbDa5L37/optics/ih2rrStHJK83rWfljKjP/sellers/TM0SPmZEqxVsYeg4gDLu
      /**
       * @type {string}
       */
      const docRef = state.user.adminDocRef || state.user.sellerDocRef

      if (!docRef) {
        return
      }

      const parts = docRef.split('/')

      const docs = []
      for (let i = 0; i < parts.length; i += 2) {
        let path = ''
        if (docs.length) {
          path += docs[i / 2 - 1] + '/'
        }

        path += `${parts[i]}/${parts[i + 1]}`
        docs.push(path)
      }

      docs.forEach((path) => {
        new Controller().listen(path.split('/'), (doc) => {
          if (!doc.exists()) {
            return
          }

          const data = doc.data()

          if (data.deletedAt && !state.inactive.includes(doc.id)) {
            state.inactive.push(doc.id)
          }

          if (!data.deletedAt) {
            state.inactive = state.inactive.filter((id) => id !== doc.id)
          }
        })
      })
    },

    /**
     * Clears the current access token. Used when the user logs
     * out.
     *
     * @param {Store} store the vuex store.
     */
    async logout({ commit }) {
      await authController.signOut()
      commit('SET_USER', null)
      commit('SET_USER_ID', null)
      commit('SET_USER_ADMIN_DOC', null)
    },

    async updateUserData({ commit, dispatch, state }, payload) {
      try {
        commit('SET_LOADING', true)
        const u = await new UserController().update(payload.id, payload.data)
        u.role = state.user.role
        dispatch('setUserId', u.id)
        dispatch('setUser', u)
        dispatch('loadAdminDocData', u.adminDocRef || u.sellerDocRef)
      } catch (e) {
        console.error(e)
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
