/**
 * Class responsible for keeping the login authentication properties.
 */
export default class AuthLoginModel {
  constructor({ email, password } = {}) {
    /**
     * Property that defines the user login email.
     *
     * @type {string}
     */
    this.email = email

    /**
     * Property that defines the user login password.
     *
     * @type {string}
     */
    this.password = password
  }
}
