import LaboratoryEntity from '../entities/laboratory.entity'

import Controller from './base.controller'

const collection = 'laboratories'

/**
 * Class responsible for keeping all the laboratory request logic.
 */
export default class LaboratoryController extends Controller {
  /**
   * Gets all laboratories from the database.
   *
   * @param {string} parent the parent collection path.
   * @returns a list of laboratories.
   */
  async getAll(parent) {
    const path = parent ? parent.split('/') : []

    if (!path.includes(collection)) {
      path.push(collection)
    }

    const snapshot = await super.getAll(path)

    return snapshot.docs.map((doc) =>
      LaboratoryEntity.fromFirestore({ ...doc.data(), id: doc.id }),
    )
  }

  /**
   * Gets a laboratory according to its path.
   *
   * @param {string} path the laboratory document path.
   * @returns a laboratory object.
   */
  async getOne(path) {
    const p = path.split('/').filter((el) => !!el)

    const doc = await super.getById(p)

    return LaboratoryEntity.fromFirestore({ ...doc.data(), id: doc.id })
  }

  /**
   * Listens for changes in a specific laboratory.
   *
   * @param {string} path the laboratory document path.
   * @param {(laboratoryEntity?: LaboratoryEntity) => void} callback a callback to be called when the laboratory is triggered.
   */
  listenOne(path, callback) {
    super.listen(path.split('/'), (document) => {
      if (!document.exists()) {
        return void callback(null)
      }

      callback(
        LaboratoryEntity.fromFirestore({ ...document.data(), id: document.id }),
      )
    })
  }

  /**
   * Creates a laboratory into the database.
   *
   * @param {LaboratoryEntity} data the laboratory data.
   * @param {string} parent the parent path.
   * @returns a laboratory with its id.
   */
  async create(data, parent) {
    const path = parent ? parent.split('/') : []

    if (!path.includes(collection)) {
      path.push(collection)
    }

    const result = await super.create(path, data.toFirestore())

    return {
      data: new LaboratoryEntity({ ...data, id: result.id }),
      relationPath: result.path,
    }
  }

  /**
   * Updates a laboratory from the database with the given data.
   *
   * @param {LaboratoryEntity} data the laboratory data.
   * @param {string} id the laboratory id.
   * @param {string} parent the parent path.
   * @returns a laboratory with its id.
   */
  async update(data, id, parent) {
    const path = parent ? parent.split('/') : []

    if (!path.includes(collection)) {
      path.push(collection)
    }

    if (!path.includes(id)) {
      path.push(id)
    }

    await super.update(path, data.toFirestore())
    return new LaboratoryEntity({ ...data, id })
  }

  /**
   * Deletes a laboratory from the database.
   *
   * @param {string} path the laboratory data.
   */
  async delete(path) {
    await super.delete(path)
  }

  /**
   * Soft deletes a laboratory from the database.
   *
   * @param {string} path the laboratory document path.
   * @param {string} by a path to the user responsible for soft deleting the laboratory.
   */
  async softDelete(path, by) {
    await super.update(path.split('/'), {
      deletedAt: new Date().toISOString(),
      deletedBy: by,
    })
  }

  /**
   * Restores a laboratory from a soft delete.
   *
   * @param {string} path the laboratory document path.
   */
  async restore(path) {
    await super.update(path.split('/'), {
      deletedAt: null,
      deletedBy: null,
    })
  }

  /**
   * Listens for changes in the laboratories collection.
   *
   * @param {string} path the collection path.
   * @param {(docs: LaboratoryEntity[]) => void} callback a callback to be called when the collection is triggered
   */
  listenMultiple(path, callback) {
    super.listenMultiple(path.split('/'), (snap) => {
      if (snap.empty) {
        return void callback([])
      }

      const docs = snap.docs.map((m) =>
        LaboratoryEntity.fromFirestore({ ...m.data(), id: m.id }),
      )

      callback(docs)
    })
  }
}
