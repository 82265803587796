import InvoiceEntity from '../entities/invoice.entity'

import Controller from './base.controller'

const customerPath = 'customers'
const subscriptionPath = 'subscriptions'
const invoicePath = 'invoices'

/**
 * Class that keeps the invoice request logic.
 */
export default class InvoiceController extends Controller {
  /**
   * Gets all invoices from the customer.
   *
   * @param {string} id the customer id.
   * @returns a list with all invoices.
   */
  async getInvoices(id) {
    const subscriptions = await super.getAll([
      customerPath,
      id,
      subscriptionPath,
    ])

    let invoices = []

    for (let subs in subscriptions.docs) {
      const inv = await super.getAll([
        customerPath,
        id,
        subscriptionPath,
        subscriptions.docs[subs].id,
        invoicePath,
      ])

      for (let i in inv.docs) {
        invoices.push(
          InvoiceEntity.fromFirestore({
            ...inv.docs[i].data(),
          }),
        )
      }
    }

    return invoices
  }
}
