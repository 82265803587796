import SellerEntity from '../entities/seller.entity'

import Controller from './base.controller'

const collection = 'sellers'

/**
 * Class responsible for keeping all the seller logic.
 */
export default class SellerController extends Controller {
  /**
   * Gets all sellers from the database.
   *
   * @param {string} parent the path to the parent document.
   * @returns an array of sellers.
   */
  async getAll(parent) {
    const path = parent ? parent.split('/') : []

    if (!path.includes(collection)) {
      path.push(collection)
    }

    const snapshot = await super.getAll(path)

    return snapshot.docs.map((doc) =>
      SellerEntity.fromFirestore({ ...doc.data(), id: doc.id }),
    )
  }

  /**
   * Gets a seller according to its id.
   *
   * @param {string} path the seller document path.
   * @returns a seller object.
   */
  async getOne(path) {
    const p = path.split('/')

    const doc = await super.getById(p)
    return SellerEntity.fromFirestore({ ...doc.data(), id: doc.id })
  }

  /**
   * Listens for changes in a specific seller.
   *
   * @param {string} path the seller document path.
   * @param {(seller?: SellerEntity) => void} callback a callback to be called when the seller is triggered.
   */
  listenOne(path, callback) {
    super.listen(path.split('/'), (document) => {
      if (!document.exists()) {
        return void callback(null)
      }

      callback(
        SellerEntity.fromFirestore({ ...document.data(), id: document.id }),
      )
    })
  }

  /**
   * Listens for changes in the sellers collection.
   *
   * @param {string} path the sellers collection path.
   * @param {(sellers: SellerEntity[]) => void} callback a callback to be called when the collection updates.
   */
  listenMultiple(path, callback) {
    super.listenMultiple(path.split('/'), (snap) => {
      if (snap.empty) {
        return void callback([])
      }

      const docs = snap.docs.map((s) =>
        SellerEntity.fromFirestore({ ...s.data(), id: s.id }),
      )

      callback(docs)
    })
  }

  /**
   * Creates a seller into the database.
   *
   * @param {SellerEntity} data the seller data.
   * @param {string} parent the parent path.
   * @returns a seller with its id.
   */
  async create(data, parent) {
    const path = parent ? parent.split('/') : []

    if (!path.includes(collection)) {
      path.push(collection)
    }

    const result = await super.create(path, data.toFirestore())

    return {
      data: new SellerEntity({ ...data, id: result.id }),
      relationPath: result.path,
    }
  }

  /**
   * Updates a seller from the database with the given data.
   *
   * @param {SellerEntity} data the seller data.
   * @param {string} id the seller id.
   * @param {string} parent the parent path.
   * @returns a seller with its id.
   */
  async update(data, id, parent) {
    const path = parent ? parent.split('/') : []

    if (!path.includes(collection)) {
      path.push(collection)
    }

    if (!path.includes(id)) {
      path.push(id)
    }

    await super.update(path, data.toFirestore())
    return new SellerEntity({ ...data, id: id })
  }

  /**
   * Deletes a seller from the database.
   *
   * @param {string} path the seller document path.
   */
  async delete(path) {
    await super.delete(path)
  }

  /**
   * Soft deletes a seller from the database.
   *
   * @param {string} path the seller document path.
   * @param {string} by a path to the user responsible for soft deleting the seller.
   */
  async softDelete(path, by) {
    await super.update(path.split('/'), {
      deletedAt: new Date().toISOString(),
      deletedBy: by,
    })
  }

  /**
   * Restores a seller from a soft delete.
   *
   * @param {string} path the seller document path.
   */
  async restore(path) {
    await super.update(path.split('/'), {
      deletedAt: null,
      deletedBy: null,
    })
  }
}
