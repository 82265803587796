//import i18n from '@/libs/i18n'

export default [
  {
    path: '/coming-soon/',
    name: 'soon',
    component: () => import('@/views/error/ComingSoon.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },

  {
    path: '/welcome-payment',
    name: 'welcome-payment',
    component: () => import('@/views/admin/users/HandlePaymentIntent.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },

  {
    path: '/sign-optic',
    name: 'sign-optic',
    component: () => import('@/views/sections/optics/OpticSign.vue'),
    meta: {
      pageTitle: 'signOptic',
      layout: 'full',
      breadcrumb: [
        {
          text: 'signOptic',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [3],
    },
  },
  {
    path: '/cancel-checkout',
    name: 'cancel-checkout',
    component: () => import('@/views/CancelCheckout.vue'),
    meta: {
      pageTitle: 'Lensxpert',
      layout: 'full',
      breadcrumb: [
        {
          text: 'Lensxpert',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [3],
    },
  },
  {
    path: '/request-access',
    name: 'request-access',
    component: () => import('@/views/auth/RequestAccess.vue'),
    meta: {
      pageTitle: 'Lensxpert',
      layout: 'full',
      breadcrumb: [
        {
          text: 'Lensxpert',
          active: true,
        },
      ],
      isPublic: true,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('@/views/auth/SignUp.vue'),
    meta: {
      pageTitle: 'Lensxpert',
      layout: 'full',
      breadcrumb: [
        {
          text: 'Lensxpert',
          active: true,
        },
      ],
      isPublic: true,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },
  {
    path: '/signin',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      pageTitle: 'Lensxpert',
      layout: 'full',
      breadcrumb: [
        {
          text: 'Lensxpert',
          active: true,
        },
      ],
      isPublic: true,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/sections/profile/Profile.vue'),
    meta: {
      pageTitle: 'profile',
      breadcrumb: [
        {
          text: 'profile',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },
  {
    path: '/upgradeplan',
    name: 'upgradeplan',
    component: () => import('@/views/sections/profile/ProfileUpgradePlan.vue'),
    meta: {
      pageTitle: 'upgradePlan',
      breadcrumb: [
        {
          text: 'profile',
          active: false,
          to: '/profile',
        },
        {
          text: 'upgradePlan',
          active: true,
        },
      ],
      isPublic: false,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/error/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },
  {
    path: '/',
    name: 'Public Home',
    component: () => import('@/views/public/Home.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },
  {
    path: '/measurement',
    name: 'measurement',
    component: () => import('@/views/public/Measurement.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [0, 1, 2, 3, 4, 5, 6],
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
