export default [
  {
    path: '/lens/',
    name: 'Lens',
    component: () => import('@/views/menu/Menu.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [3, 5, 6],
    },
  },
  {
    path: '/lens/occupational-lens',
    name: 'Occuppational ',
    component: () => import('@/views/menu/Occupational.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
  {
    path: '/lens/polarized-lens/1',
    name: 'Polarized lenses 1',
    component: () => import('@/views/menu/Polarizedlenses.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
  {
    path: '/lens/polarized-lens/2',
    name: 'Polarized lenses 2',
    component: () => import('@/views/menu/PolarizedLens_2.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
  {
    path: '/lens/progressive-lens',
    name: 'Progressive',
    component: () => import('@/views/menu/Progressive.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
  {
    path: '/lens/sports-lens',
    name: 'Sports ',
    component: () => import('@/views/menu/Sports.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
  {
    path: '/lens/simple-lens',
    name: 'Simple Lens',
    component: () => import('@/views/menu/SimpleLens.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
  {
    path: '/lens/photosensitive',
    name: 'Photosensitive ',
    component: () => import('@/views/menu/Photosensitive.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
  {
    path: '/lens/blue-filter',
    name: 'Blue-filter',
    component: () => import('@/views/menu/Blue_Filter.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
  {
    path: '/lens/thickness',
    name: 'Thickness',
    component: () => import('@/views/menu/Thickness.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
  {
    path: '/lens/anti-glare/1',
    name: 'Anti-Glare 1',
    component: () => import('@/views/menu/Anti_Glare.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
  {
    path: '/lens/anti-glare/2',
    name: 'Anti-Glare 2',
    component: () => import('@/views/menu/Anti_Glare_Scratchs.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
  {
    path: '/lens/anti-glare/3',
    name: 'Anti-Glare 3',
    component: () => import('@/views/menu/Anti_Glare_Water.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
  {
    path: '/lens/anti-glare/4',
    name: 'Anti-Glare 4',
    component: () => import('@/views/menu/Anti_Glare_Stains.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
  {
    path: '/lens/anti-glare/5',
    name: 'Anti-Glare 5',
    component: () => import('@/views/menu/Anti_Glare_Dirt.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      hasAccess: [2, 3, 6],
    },
  },
]
