import ReasonEnum from '../utils/credits'
import Entity from './base.entity'

/**
 * Class that represents the credit entity, used to contain data from credit
 * transactions (measurements) between entities.
 */
export class CreditEntity extends Entity {
  constructor({
    id,
    createdAt,
    updatedAt,
    ownerRef,
    targetRef,
    date,
    difference,
    remaining,
    reason,
  } = {}) {
    super({ id, createdAt, updatedAt })

    /**
     * Property that defines the user/entity reference path that is linked
     * to the credit.
     *
     * @default null
     * @type {string}
     */
    this.ownerRef = ownerRef ?? null

    /**
     * Property that defines the document path reference of who suffered
     * the transaction.
     *
     * @default null
     * @type {string}
     */
    this.targetRef = targetRef ?? null

    /**
     * Property that defines the transaction date.
     *
     * @example
     * new CreditEntity().date = Date.now()
     *
     * @default Date.now()
     * @type {number}
     */
    this.date = date ?? Date.now()

    /**
     * Property that defines the credit transaction amount. It may be any
     * value, from -Infinity to +Infinity, being limited only by the credit
     * amount of the entity.
     *
     * @default 0
     * @type {number}
     */
    this.difference = difference ?? 0

    /**
     * Property that defines how many measurements/credits the entity has
     * left after the transaction.
     *
     * @default 0
     * @type {number}
     */
    this.remaining = remaining ?? 0

    /**
     * Property that defines the transaction reason.
     *
     * @default ReasonEnum.UNKNOWN
     * @type {string}
     */
    this.reason = reason ?? ReasonEnum.UNKNOWN
  }

  /**
   * Gets a map object that represents the credit data, used to send it to
   * Cloud Firestore.
   */
  toFirestore() {
    return {
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      ownerRef: this.ownerRef,
      targetRef: this.targetRef,
      date: this.date,
      difference: this.difference,
      remaining: this.remaining,
      reason: this.reason,
    }
  }

  /**
   * Generates a CreditEntity according to the given map data.
   *
   * @param {CreditEntity} data the Firestore credit data.
   */
  static fromFirestore(data) {
    if (!data) {
      null
    }

    return new CreditEntity(data)
  }

  /**
   * Gets a list of credit entities from the given data.
   *
   * @param {CreditEntity[]} data an array of credit maps.
   * @returns a list of credit entities.
   */
  static fromFirestoreArray(data) {
    if (!data) {
      return []
    }

    return data.map((c) => this.fromFirestore(c))
  }
}
