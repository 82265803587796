import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/libs/i18n'
import authGuard from './auth.guard'
import { connectStorageEmulator } from 'firebase/storage'
import consultor from './routes/consultor'
import tools from './routes/tools'
import admin from './routes/admin'
import system from './routes/system'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...consultor,
    ...tools,
    ...admin,
    ...system
  ],
})

router.beforeEach(authGuard)

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
