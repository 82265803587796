import Entity from './base.entity'

/**
 * Class that represents the auth link entity.
 */
export default class AuthLinkEntity extends Entity {
  constructor({
    id,
    email,
    role,
    relationPath,
    createdAt,
    updatedAt,
    plan,
    isTrialPlan,
    trialDays,
    planType,
  } = {}) {
    super({ id, createdAt, updatedAt })

    /**
     * Property that defines the user email.
     *
     * @type {string}
     */
    this.email = email

    /**
     * Property that defines the user role.
     *
     * @type {number}
     */
    this.role = role

    /**
     * Property that defines the user relation document id.
     *
     * @type {string}
     */
    this.relationPath = relationPath

    /**
     * Property that defines the plan ID that will be given to the admin
     *
     * @type {string}
     */
    this.plan = plan ?? null

    /**
     * Property that defines the if it is a trial plan
     *
     * @type {boolean}
     */
    this.isTrialPlan = isTrialPlan ?? false

    /**
     * Property that defines the trial days.
     *
     * @type {number}
     */
    this.trialDays = trialDays

    /**
     * Property that defines the plan type (external | stripe)
     *
     * @type {string}
     */
    this.planType = planType ?? null
  }

  /**
   * Gets an object that represents the auth link properties.
   */
  toFirestore() {
    return {
      email: this.email,
      role: this.role,
      relationPath: this.relationPath,
      plan: this.plan,
      isTrialPlan: this.isTrialPlan,
      trialDays: this.trialDays,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      planType: this.planType,
    }
  }
}
