import {
  checkActionCode,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signOut,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  useDeviceLanguage,
} from 'firebase/auth'
import { where } from 'firebase/firestore'

import { actionCodeSettings, auth } from '@/api/firebase'

import AuthLinkEntity from '../entities/auth-link.entity'
import AuthLoginModel from '../models/auth-login.model'
import UserEntity from '../entities/user.entity'

import Controller from './base.controller'

const collection = 'users'
const linkCollection = 'auth-links'

/**
 * Class responsible for dealing with authentication logic,
 * such as log in and out.
 */
export default class AuthController extends Controller {
  /**
   * Signs up the user using its email and password.
   *
   * @param {Object} data an object containing the user data and its password.
   * @param {string} data.password the user password.
   * @param {UserEntity} data.user the user data.
   * @returns {Promise<{user: UserEntity} | {error: Object}>} the user data.
   * @throws a Firebase exception.
   */
  async signUp(data) {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(
        auth,
        data.user.email,
      )

      if (!signInMethods.length) {
        const user = await createUserWithEmailAndPassword(
          auth,
          data.user.email,
          data.password,
        )

        const now = new Date().toISOString()

        data.user.createdAt = now
        data.user.updatedAt = now

        await super.setDataById(
          [collection, user.user.uid],
          data.user.toFirestore(),
        )
        return {
          user: new UserEntity({ ...data.user, id: user.user.uid }),
        }
      } else {
        throw new Error('Email Already in Use')
      }
    } catch (error) {
      return {
        error,
      }
    }
  }

  /**
   * Signs in the user using its email and password.
   *
   * @param {AuthLoginModel} data the user email.
   * @returns {Promise<{user: UserEntity} | {error: Object}>} the user data.
   * @throws a Firebase exception.
   */
  async signIn(data) {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password,
      )

      const userData = await super.getById([collection, user.user.uid])

      return {
        user: UserEntity.fromFirestore({ ...userData.data(), id: userData.id }),
      }
    } catch (error) {
      return {
        error,
      }
    }
  }

  /**
   * Auto sign's in the user using the currentUser method from Firebase.
   *
   * @returns {Promise<{user: UserEntity} | {error: Object}>} the user data.
   * @throws a Firebase exception.
   */
  async autoSignIn() {
    try {
      const user = await this.getCurrentUser()
      const userData = await super.getById([collection, user.uid])

      return {
        user: UserEntity.fromFirestore({ ...userData.data(), id: userData.id }),
      }
    } catch (error) {
      return {
        error,
      }
    }
  }

  /**
   * Private method to get the current subscribed user on firebase.
   *
   * @returns {Promise<{user: UserEntity} | {error: Object}>} the user data.
   * @throws a Firebase exception.
   * @private
   */
  getCurrentUser() {
    return new Promise((res, rej) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe()

        res(user)
      }, rej)
    })
  }

  /**
   * Sends a sign in link to the given email.
   *
   * @param {Object} data An object containing the user email the auth link id.
   * @throws {FirebaseException}
   */
  async sendSignInLink({ email, authLinkId }) {
    useDeviceLanguage(auth)

    await sendSignInLinkToEmail(auth, email, actionCodeSettings(authLinkId))
  }

  /**
   * Signs the user in using an email link.
   *
   * @param {Object} data an object containing the email and link.
   * @param {string} data.email the email to be used.
   * @param {string} data.link the email link.
   * @throws {FirebaseException}
   */
  async signInWithLink(data) {
    if (!isSignInWithEmailLink(auth, data.link)) {
      throw new Error('invalid-email-link')
    }
    useDeviceLanguage(auth)

    return signInWithEmailLink(auth, data.email, data.link)
  }

  /**
   * Gets an auth link from the Firestore by its id.
   *
   * @param {string} id The auth link id.
   * @returns an auth link with its id.
   */
  async getAuthLink(id) {
    const authLink = await super.getById([linkCollection, id])

    return new AuthLinkEntity({ ...authLink.data(), id })
  }

  /**
   * Creates an auth link into the Firestore.
   *
   * @param {AuthLinkEntity} data The auth link to be set.
   * @returns an auth link with its id.
   */
  async createAuthLink(data) {
    const result = await super.create([linkCollection], data.toFirestore())

    return new AuthLinkEntity({ ...data, id: result.id })
  }

  /**
   * Deletes an auth link from the Firestore.
   *
   * @param {string} id The auth link id.
   */
  async deleteAuthLink(id) {
    await super.delete(`${linkCollection}/${id}`)
  }

  /**
   * Verifies whether an OOB code is valid.
   *
   * @param {string} data the OOB code to be checked.
   * @returns a string that represents the result.
   * @throws {FirebaseException}
   */
  async verifyOobCode(data) {
    return checkActionCode(auth, data)
  }

  /**
   * Signs out the current user.
   */
  async signOut() {
    await signOut(auth)
  }

  /**
   * Checks whether an user exists.
   *
   * @param {string} email the user email.
   */
  async checkIfUserExists(email) {
    const users = await super.getAll(['users'], [where('email', '==', email)])

    return !users.empty
  }

  /**
   * Checks whether an user was invited by its email.
   *
   * @param {string} email the user email.
   */
  async checkIfEmailWasInvited(email) {
    const links = await super.getAll(
      ['auth-links'],
      [where('email', '==', email)],
    )

    return !links.empty
  }

  /**
   * Resets an user password by its email.
   *
   * @param {string} email the user email.
   */
  async resetPassword(email) {
    useDeviceLanguage(auth)

    return sendPasswordResetEmail(auth, email)
  }
}
